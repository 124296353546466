import { observable, makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import WebFont from "webfontloader";

import axios from "axios";
export class DataService {
  user;
  notificationService;
  authService;
  socketService;

  constructor(notificationService, authService, socketService) {
    this.notificationService = notificationService;
    this.authService = authService;
    this.socketService = socketService;

    makeAutoObservable(this);
  }

  setter = (propertyName, value) => {
    this[propertyName] = value;
  };

  getter = (propertyName) => {
    return this[propertyName];
  };

  dataRequest = async (body, noNotification = false) => {
    const dataResponse = await axios({
      url: `https://api-prd-ap-southeast-2-data.kzen8.com/${body.url ?? "data/item/retrieveItemsFromFilter"}`,
      method: "POST",
      data: {
        postData: body,
      },
    });

    if (dataResponse.data.status !== 1 && !noNotification) {
      this.notificationService.addNotifications([{ severity: "error", summary: dataResponse.data.message, detail: dataResponse.data.errors }]);
    }

    if (dataResponse.data.status === 1 && !noNotification) {
      this.notificationService.addNotifications([{ severity: "success", summary: dataResponse.data.message, detail: dataResponse.data.errors }]);
    }

    if (dataResponse.data.status === 4) {
      this.notificationService.addNotifications([{ severity: "warning", summary: dataResponse.data.message, detail: dataResponse.data.errors }]);

      this.authService.signOut();
    }

    return dataResponse.data;
  };

  dataSocketRequest = async (body, handler, noNotification = false) => {
    const handlerFunc = (data, requestId) => {
      console.log(data, requestId);

      if (handler) {
        handler(data, requestId);
      }

      if (data.status !== 1 && !noNotification) {
        this.notificationService.addNotifications([{ severity: "error", summary: data.message, detail: data.errors }]);
      }

      if ((data.status === 1 || data.status === true) && !noNotification) {
        this.notificationService.addNotifications([{ severity: "success", summary: data.message, detail: data.errors }]);
      }

      if (data.status === 4) {
        this.notificationService.addNotifications([{ severity: "warning", summary: data.message, detail: data.errors }]);

        this.authService.signOut();
      }
    };

    console.log(this.socketService.writableSocket);

    const requestId = this.socketService.writableSocket.sendMessage(
      {
        action: body.action,
        request: {
          itemId: body.itemId,
          table: body.tableId,
          itemData: body.itemData
        },
      },
      false,
      handlerFunc
    );
  };
}
