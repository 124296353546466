import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";
import PropertyElementBox from "../property-element-box/property-element-box";
import PropertyGroupBox from "../property-group-box/property-group-box";
import PropertyPageBox from "../property-page-box/property-page-box";
import { Accordion, AccordionTab } from "primereact/accordion";
import "./property-box.scss";
import EditProjectForm from "../edit-project-form/edit-project-form";

const PropertyBox = observer(({ appStructureTreeViewService, currentProject }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="propertyBox">
      {/* 
      <AccordionTab header="App variables">
        <PropertyPageBox />
      </AccordionTab> */}

      <AccordionTab header="Project Settings">
        <EditProjectForm appStructureTreeViewService={appStructureTreeViewService} currentProject={currentProject}/>
      </AccordionTab>

      <AccordionTab header="Page Settings">
        <PropertyPageBox />
      </AccordionTab>

      {appStructureTreeViewService && appStructureTreeViewService.getMode() !== "designer" ? (
        <AccordionTab header="Group Settings">
          <PropertyGroupBox />
        </AccordionTab>
      ) : (
        ""
      )}

      <AccordionTab header="Element Settings">
        <PropertyElementBox />
      </AccordionTab>
    </Accordion>
  );
});

export default PropertyBox;
