import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { getStyledCssForGroup } from "../../utils/getStyledCssForGroup";
import DomElement from "../dom-element/dom-element";
import * as _ from "lodash";

const DomGroup = observer(({page, group, appStructureTreeViewService }) => {

  useEffect(() => {
    const domGroup = document.getElementById(group.id);

    const potentialNewPositionalProperties = {
      x: domGroup.offsetLeft,
      y: domGroup.offsetTop,
      width: domGroup.clientWidth,
      height: domGroup.clientHeight,
    };

    if (domGroup && !_.isEqual(appStructureTreeViewService.positionalProperties[group.id], potentialNewPositionalProperties)) {
      appStructureTreeViewService.setter("positionalProperties", {
        ...appStructureTreeViewService.positionalProperties,
        [group.id]: potentialNewPositionalProperties,
      });
    }
  }, [group, page, appStructureTreeViewService.positionalProperties])

  return (
    <div id={group.id}>
      {group && appStructureTreeViewService
        ? appStructureTreeViewService.elements
          .filter((element) => !element?.isHidden && element.groupId === group.id && element.parentId === group.id)
          .map((element, idx) => (
            <DomElement
              page={page}
              group={group}
              key={idx}
              element={element}
              onChange={appStructureTreeViewService.onElementalPropertyChange}
              count={idx}
              onSelect={appStructureTreeViewService.selectElement}
              addElement={appStructureTreeViewService.addElementToSelection}
              appStructureTreeViewService={appStructureTreeViewService}
            />
          ))
        : null}

      <style jsx>
        {`
          #${group.id} {
            ${getStyledCssForGroup(group)}
          }
        `}
      </style>
    </div>
  );
});

export default DomGroup;
