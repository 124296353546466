import { convertPropertiesToCss } from "./convertPropertiesToCss";
import * as _ from 'lodash';

export const getStyledCssForGroup = (group) => {
  const groupClone = _.cloneDeep(group);
  let stringValue = "";
  groupClone.type = "group";
  const styles = convertPropertiesToCss(groupClone);

  for (const key of Object.keys(styles)) {
    stringValue = stringValue + `${key} : ${styles[key]}; \n`;
  }

  return stringValue;
};
