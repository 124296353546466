import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button, Toast } from "primereact";
import "./signin.scss";
import { BiUser } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { BsMegaphoneFill } from "react-icons/bs";

const Signin = observer(({ authService, notificationService }) => {
  const [userDetails, setUserDetails] = useState({});

  const navigate = useNavigate();

  const onDetailsChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.id]: e.target.value,
    });
  };

  const onGuestLogin = (e) => {
    e.stopPropagation();
    authService.guestSignin(navigate);
  };

  return (
    <div className="Signin">
      <img src="/Vector 1.svg" alt="logo" className="wave" />

      <img src="/Vector 1.svg" alt="logo" className="wave2" />

      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <img src="/Studio K Logo.svg" alt="logo" className="logo" />
        <h3 className="">
          Sign In <BiUser />{" "}
        </h3>

        <InputText value={userDetails?.email ?? ""} onChange={onDetailsChange} id="email" placeholder="Email"></InputText>
        <Password value={userDetails?.password} onChange={onDetailsChange} inputId="password" placeholder="Password" toggleMask feedback={false}></Password>
        <div className="p-d-flex p-ai-center button-container">
          <Button onClick={onGuestLogin} className="p-button-info" label="Guest Login" disabled={authService.authBusy} loading={authService.authBusy && authService.isGuestLogin} icon={<BiUser />} iconPos={"right"}></Button>

          <Button label="Signin" onClick={() => authService?.signin(navigate, userDetails)} disabled={authService.authBusy} loading={authService.authBusy && !authService.isGuestLogin} icon={<BiUser />} iconPos={"right"}></Button>
        </div>

        {/* <Link to={"/signup"}>Don't have an account? Signup here</Link> */}
        {/* <span>Releasing soon!!!</span> */}
      </form>
      <div className="coming-soon-banner">
        <BsMegaphoneFill></BsMegaphoneFill>Releasing soon<br/> Current version: Beta 0.1.3
      </div>
    </div>
  );
});

export default Signin;
