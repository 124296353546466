import { observable, makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import WebFont from "webfontloader";
import cuid from "cuid";
import axios from "axios";

export class AuthService {
  user;
  notificationService;
  socketService;
  authBusy = false;
  isGuestLogin = false;

  constructor(notificationService, socketService) {
    this.notificationService = notificationService;
    this.socketService = socketService;
    makeAutoObservable(this);
  }

  setter = (propertyName, value) => {
    this[propertyName] = value;
  };

  getter = (propertyName) => {
    return this[propertyName];
  };

  signin = async (navigate, userDetails) => {

    this.authBusy = true;

    const signInResponse = await axios({
      url: "https://api-prd-ap-southeast-2-ui.kzen8.com/ui/auth/signInNexusApplication",
      method: "POST",
      data: {
        postData: {
          signInNameorEmail: userDetails.email,
          signInCulture: "en-AU",
          signInApplication: "studiok",
          signInDomain: "studiok",
          signInPassword: userDetails.password
        },
      },
    });

    if (signInResponse.data.status === 1) {
      this.user = signInResponse.data.data;
      this.socketService.createWritableSocket(this.user);
      this.notificationService.notifications = this.notificationService.notifications.concat({ severity: 'success', summary: 'Sign in success', detail: signInResponse.data.message })
      navigate('/dashboard');
    } else {
      this.notificationService.notifications = this.notificationService.notifications.concat({ severity: 'error', summary: 'Sign in failed', detail: signInResponse.data.message })
    }

    this.authBusy = false;

  };



  guestSignin = async (navigate) => {

    this.authBusy = true;
    this.isGuestLogin = true;

    const signInResponse = await axios({
      url: "https://api-prd-ap-southeast-2-ui.kzen8.com/ui/auth/signInNexusApplication",
      method: "POST",
      data: {
        postData: {
          signInNameorEmail: 'guest',
          signInCulture: "en-AU",
          signInApplication: "studiok",
          signInDomain: "studiok",
          signInPassword: 'Password_1'
        },
      },
    });

    if (signInResponse.data.status === 1) {
      this.user = signInResponse.data.data;
      this.notificationService.notifications = this.notificationService.notifications.concat({ severity: 'success', summary: 'Sign in success', detail: signInResponse.data.message })
      navigate('/dashboard');
    } else {
      this.notificationService.notifications = this.notificationService.notifications.concat({ severity: 'error', summary: 'Sign in failed', detail: signInResponse.data.message })
    }

    this.authBusy = false;

  };


  signOut = () => {
    this.notificationService.addNotifications([{ severity: 'success', summary: 'Log out success', detail: 'Log out successfully' }])

    this.isGuestLogin = false;
    this.user = undefined;
  }

  signup = async (navigate, newUserDetails) => {

    this.authBusy = true;

    const signUpResponse = await axios({
      url: "https://api-prd-ap-southeast-2-ui.kzen8.com/ui/auth/signUpNexusApplication",
      method: "POST",
      data: {
        postData: {
          signUpEmail: newUserDetails.email,
          signUpFullName: newUserDetails.fullName,
          signUpCulture: "en-AU",
          signUpApplication: "studiok",
          signUpDomain: "studiok",
          signUpClientType: newUserDetails.accountType,
        },
      },
    });

    if (signUpResponse.data.status) {
      this.notificationService.notifications = this.notificationService.notifications.concat({ severity: 'success', summary: 'Sign up success', detail: signUpResponse.data.message })
      navigate('/signin');
    }
    else {
      this.notificationService.notifications = this.notificationService.notifications.concat({ severity: 'error', summary: 'Sign up failed', detail: signUpResponse.data.message })
    }

    this.authBusy = false;

  };
}
