import React from "react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useState } from "react";
import { Rect, Text, Ellipse, Image as KImage } from "react-konva";
import _ from "lodash";

const CanvasElement = observer(({ element, onChange, count, onSelect, addElement, appStructureTreeViewService }) => {
  let thisElem = null;

  const convertPropertyToProperFormat = (properties) => {
    const propertyClone = _.cloneDeep(properties);

    for (const property of Object.keys(propertyClone)) {
      const propertyType = appStructureTreeViewService.getElementPropertyTypes(element)[property];

      if (propertyType) {
        const newImage = new Image();

        switch (`${propertyType.type} ${propertyType.format}`) {
          case "text image":
          case "text fillPatternImage":
            newImage.src = propertyClone[property];
            propertyClone[property] = newImage;
            break;
          default:
            break;
        }
      }
    }
    console.log(propertyClone);
    return propertyClone;
  };

  //when transform ends
  const transformEnds = function (e) {
    // transformer is changing scale of the node
    // and NOT its width or height
    // but in the store we have only width and height
    // to match the data better we will reset scale on transform end

    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    onChange(
      {
        ...element.properties,
        x: node.x(),
        y: node.y(),
        rotation: node.rotation(),
        // set minimal value
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(node.height() * scaleY),
        widthUnit: "px",
        heightUnit: "px",
      },
      element
    );

    // we will reset it back
    node.scaleX(1);
    node.scaleY(1);
  };

  const onDragEnd = function (e) {

    onChange(
      {
        ...element.properties,
        x: e.target.x(),
        y: e.target.y(),
      },
      element
    );
  };

  const onShapeClick = function (e) {

    if (e.evt.ctrlKey) {
      addElement(element);
    } else {
      onSelect(element, true);
    }
  };

  const isElementDraggable = (element)=>{
    return (["absolute", "fixed", "relative"].includes(element?.properties?.position) || appStructureTreeViewService.mode === "designer") && appStructureTreeViewService.selectedElements.map(e=>e.id).includes(element.id);
  }

  const getPropertyToPass = () => {
    const propertyToPass = {};
    for (const prop in element.properties) {
      if (appStructureTreeViewService.getElementPropertyTypes(element)[prop] && !appStructureTreeViewService.getElementPropertyTypes(element)[prop].avoidCanvasApply) {
        propertyToPass[prop] = element.properties[prop];
      }
    }
    if (appStructureTreeViewService.positionalProperties[element.id]) {
      propertyToPass["x"] = appStructureTreeViewService.positionalProperties[element.id].x;
      propertyToPass["y"] = appStructureTreeViewService.positionalProperties[element.id].y;
      propertyToPass["width"] = appStructureTreeViewService.positionalProperties[element.id].width;
      propertyToPass["height"] = appStructureTreeViewService.positionalProperties[element.id].height;
    }

    return propertyToPass;
  };

  switch (element.type) {
    case "rect":
      thisElem = (
        <Rect
          {...convertPropertyToProperFormat(getPropertyToPass())}
          id={element.id}
          name={'object'}
          key={element.type + count}
          draggable={isElementDraggable(element)}
          onClick={onShapeClick}
          onTransformEnd={transformEnds}
          onDragEnd={onDragEnd}
        ></Rect>
      );
      break;
    case "circle":
      thisElem = (
        <Ellipse
          {...convertPropertyToProperFormat(getPropertyToPass())}
          id={element.id}
          name={'object'}
          key={element.type + count}
          draggable={isElementDraggable(element)}
          onClick={onShapeClick}
          onTransformEnd={transformEnds}
          onDragEnd={onDragEnd}
        ></Ellipse>
      );
      break;
    case "text":
      thisElem = (
        <Text
          {...convertPropertyToProperFormat(getPropertyToPass())}
          id={element.id}
          name={'object'}
          key={element.type + count}
          draggable={isElementDraggable(element)}
          onClick={onShapeClick}
          onTransformEnd={transformEnds}
          onDragEnd={onDragEnd}
        ></Text>
      );
      break;
    case "image":
      thisElem = (
        <KImage
          {...convertPropertyToProperFormat(getPropertyToPass())}
          id={element.id}
          name={'object'}
          key={element.type + count}
          draggable={isElementDraggable(element)}
          onClick={onShapeClick}
          onTransformEnd={transformEnds}
          onDragEnd={onDragEnd}
        ></KImage>
      );
      break;
    default:
      break;
  }

  return <>{thisElem}</>;
});

export default CanvasElement;
