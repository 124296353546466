export const groupProperties = {
  display: {
    name: "display",
    label: "Display",
    type: "text",
    format: "select",
    options: ["none", "block", "flex", "inline-flex"]
  },
  // position: {
  //   name: "position",
  //   label: "position",
  //   type: "text",
  //   format: "select",
  //   options: ["fixed", "absolute", "relative", "unset"],
  //   avoidCanvasApply: true
  // },
  // x: {
  //   name: "x",
  //   type: "decimal",
  //   format: "inputBox",
  //   displayCondition: {
  //     ors: [
  //       {
  //         ands: [
  //           {
  //             property: "position",
  //             value: "absolute",
  //           },
  //         ],
  //       },
  //       {
  //         ands: [
  //           {
  //             property: "position",
  //             value: "relative",
  //           },
  //         ],
  //       },
  //       {
  //         ands: [
  //           {
  //             property: "position",
  //             value: "fixed",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  // y: {
  //   name: "y",
  //   type: "decimal",
  //   format: "inputBox",
  //   displayCondition: {
  //     ors: [
  //       {
  //         ands: [
  //           {
  //             property: "position",
  //             value: "absolute",
  //           },
  //         ],
  //       },
  //       {
  //         ands: [
  //           {
  //             property: "position",
  //             value: "relative",
  //           },
  //         ],
  //       },
  //       {
  //         ands: [
  //           {
  //             property: "position",
  //             value: "fixed",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // },
  width: {
    name: "width",
    type: "decimal",
    format: "inputBox",
  },
  widthUnit: {
    name: "widthUnit",
    type: "text",
    format: "select",
    options: ["px", "%", "rem", "vw"],
    defaultValue: "px",
    developerOnly: true
  },
  height: {
    name: "height",
    type: "decimal",
    format: "inputBox",
  },
  heightUnit: {
    name: "heightUnit",
    type: "text",
    format: "select",
    options: ["px", "%", "rem", "vh"],
    defaultValue: "px",
    developerOnly: true
  },
  padding: {
    name: "padding",
    type: "decimal",
    format: "inputBox",
    developerOnly: true
  },
  paddingUnit: {
    name: "paddingUnit",
    type: "text",
    format: "select",
    options: ["px", "%", "rem"],
    defaultValue: "px",
    developerOnly: true
  },
  margin: {
    name: "margin",
    type: "decimal",
    format: "inputBox",
    developerOnly: true
  },
  marginUnit: {
    name: "marginUnit",
    type: "text",
    format: "select",
    options: ["px", "%", "rem"],
    defaultValue: "px",
    developerOnly: true
  },
  fill: {
    name: "fill",
    type: "colour",
    format: "inputBox",
  },
  cornerRadius: {
    name: "cornerRadius",
    type: "decimal",
    format: "inputBox",
  },
  fontFamily: {
    name: "fontFamily",
    type: "text",
    format: "select",
    options: ["Roboto", "Pacifico", "Hectiva", "Cormorant Garamond", "Lato", "Open Sans", "Montserrat", "Oswald", "Source Sans Pro", "Slabo", "Raleway", "PT Sans"],
    passAsInheritance: true
  },
  fontSize: {
    name: "fontSize",
    type: "number",
    format: "inputBox",
    passAsInheritance: true
  },
  fontStyle: {
    name: "fontStyle",
    type: "text",
    format: "select",
    options: ["nomal", "bold", "italic", "italic bold"],
    passAsInheritance: true
  },
  fillPatternX: {
    name: "fillPatternX",
    type: "number",
    format: "inputBox",
  },
  fillPatternY: {
    name: "fillPatternY",
    type: "number",
    format: "inputBox",
  },
  text: {
    name: "text",
    type: "text",
    format: "textArea",
  },
  align: {
    name: "align",
    label: "Text align",
    type: "text",
    format: "select",
    options: ["left", "center", "right"],
    passAsInheritance: true
  },
  opacity: {
    name: 'opacity',
    type: 'decimal',
    format: 'inputBox'
  }
};
