import { convertPropertiesToCss } from "./convertPropertiesToCss";

export const getStyledCssForElementType = (element) => {
    let stringValue = "";

    const styles = convertPropertiesToCss(element);

    for (const key of Object.keys(styles)) {
      stringValue = stringValue + `${key} : ${styles[key]}; \n`;
    }

    return stringValue;
  };