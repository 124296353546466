import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { getStyledCssForElementType } from "../../utils/getStyledCssForElementType";

const DomElement = observer(({page, group, element, appStructureTreeViewService }) => {
  let thisElem = null;

  useEffect(() => {
    const domElement = document.getElementById(element.id);


    const potentialNewPositionalProperties = {
      x: domElement.offsetLeft,
      y: domElement.offsetTop,
      width: domElement.clientWidth,
      height: domElement.clientHeight,
    };

    if (domElement && !_.isEqual(appStructureTreeViewService.positionalProperties[element.id], potentialNewPositionalProperties)) {
      appStructureTreeViewService.setter("positionalProperties", {
        ...appStructureTreeViewService.positionalProperties,
        [element.id]: potentialNewPositionalProperties,
      });
    }
  }, [element, page, group, appStructureTreeViewService.positionalProperties])


  switch (element.type) {
    case "rect":
      thisElem = (<div id={element.id}></div>);
      break;
    case "circle":
      thisElem = (<div id={element.id}></div>);
      break;
    case "text":
      thisElem = (<p id={element.id}>{element.properties?.text}</p>);
      break;
    case "image":
      thisElem = (<img src={element.properties.image} id={element.id} />);
      break;
    default:
      break;
  }

  return (
    <>
      {thisElem}
      {appStructureTreeViewService.elements
        .filter((elem) => !elem.isHidden && elem.parentId === element.id)
        .map((elem, idx) => (
          <DomElement
            key={idx}
            element={elem}
            onChange={appStructureTreeViewService.onElementalPropertyChange}
            count={idx}
            onSelect={appStructureTreeViewService.selectElement}
            addElement={appStructureTreeViewService.addElementToSelection}
            appStructureTreeViewService={appStructureTreeViewService}
          />
        ))}

      <style jsx>
        {`
          #${element.id} {
            ${element.type === "circle" ? `border-radius: ${"10000000000000px"}` : ""};
            ${getStyledCssForElementType(element)}
          }
        `}
      </style>
    </>
  );
});

export default DomElement;
