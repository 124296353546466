import cuid from "cuid";

export class CircleTool {
  type = "circle";
  id = cuid();
  properties = {
    isComponent: false,
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    treeIcon: 'pi pi-fw pi-circle'
  };
}
