import { observer } from 'mobx-react-lite'
import { Button } from 'primereact/button'
import React from 'react'
import { useEffect } from 'react'
import { getStyledCssForPage } from '../../utils/getStyledCssForPage'
import DomGroup from '../dom-group/dom-group';
import * as _ from 'lodash';

const DomPage = observer(({ page, appStructureTreeViewService, toggleDom }) => {

  useEffect(() => {
    if (page) {
      const domPage = document.getElementById(page.id);

      const potentialNewPositionalProperties = {
        x: domPage.offsetLeft,
        y: domPage.offsetTop,
        width: domPage.clientWidth,
        height: domPage.clientHeight,
      };

      if (domPage && !_.isEqual(appStructureTreeViewService.positionalProperties[page.id], potentialNewPositionalProperties)) {
        appStructureTreeViewService.setter("positionalProperties", {
          ...appStructureTreeViewService.positionalProperties,
          [page.id]: potentialNewPositionalProperties,
        });
      }
    }
  }, [page, appStructureTreeViewService.positionalProperties])

  return (
    page ? <div id={page.id}>
      <Button className="p-mr-2 dom-close-button" label="Close Dom" onClick={() => toggleDom(false)} icon="pi pi-eye" iconPos="right"></Button>
      {appStructureTreeViewService.groups.filter((group) => !group.isHidden && group.pageId === page.id).map((group, idx) => <DomGroup key={idx} group={group} page={page} appStructureTreeViewService={appStructureTreeViewService} />)}
      <style jsx>
        {
          `
            #${page.id}{
                position: fixed;
                z-index: 1000000;
                ${getStyledCssForPage(page)}
            }
            `
        }
      </style>
    </div> : ''
  )
})

export default DomPage