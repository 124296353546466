import cuid from "cuid";

export class RectTool {


  type = "rect";
  id = cuid();
  properties = {
    isComponent: false,
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    fill: "#ccc",
    fillPaternX: 100,
    fillPaternY: 100,
    treeIcon: 'pi pi-fw pi-stop'
  };

}
