const propertyToCssMap = {
  rect: {
    x: "left",
    y: "top",
    width: "width",
    height: "height",
    fill: "background-color",
    fillPatternImage: "background-image",
    fillPatternRepeat: "background-repeat",
    cornerRadius: "border-radius",
    fontFamily: "font-family",
    fontSize: "font-size",
    opacity: "opacity",
    align: "text-align",
    rotation: "transform"
  },
  circle: {
    x: "left",
    y: "top",
    width: "width",
    height: "height",
    fill: "background-color",
    cornerRadius: "border-radius",
    fontFamily: "font-family",
    fontSize: "font-size",
    opacity: "opacity",
    align: "text-align",
    rotation: "transform"
  },
  image: {
    x: "left",
    y: "top",
    width: "width",
    height: "height",
    fill: "background-color",
    cornerRadius: "border-radius",
    fontFamily: "font-family",
    fontSize: "font-size",
    opacity: "opacity",
    align: "text-align",
    rotation: "transform"
  },
  text: {
    x: "left",
    y: "top",
    width: "width",
    height: "height",
    fill: "color",
    cornerRadius: "border-radius",
    fontFamily: "font-family",
    fontSize: "font-size",
    fontStyle: "font-weight",
    opacity: "opacity",
    align: "text-align",
    rotation: "transform"
  },
  page: {
    x: "left",
    y: "top",
    width: "width",
    height: "height",
    fill: "background-color",
    cornerRadius: "border-radius",
    fontFamily: "font-family",
    fontSize: "font-size",
    fontStyle: "font-weight",
    opacity: "opacity",
    align: "text-align",
    rotation: "transform"
  },
  group: {
    x: "left",
    y: "top",
    width: "width",
    height: "height",
    fill: "background-color",
    cornerRadius: "border-radius",
    fontFamily: "font-family",
    fontSize: "font-size",
    fontStyle: "font-weight",
    opacity: "opacity",
    align: "text-align",
    rotation: "transform"
  },
};

const getPropertyValueForEachCssProperty = (cssProperty, value, element) => {
  switch (cssProperty) {
    case "background-image":
      return `url('${value}')`;
    case "width":
    case "height":
    case "top":
    case "left":
    case "font-size":
    case "border-radius":
      return `${value}${element.properties[`${cssProperty}Unit`] ? element.properties[`${cssProperty}Unit`] : 'px'}`;
    case "transform":
        return `rotateZ(${value}deg)`;

    default:
      return value;
  }
};

export const convertPropertiesToCss = (element) => {
  const styles = {};
  if (!element.type) {
    element.type = "default";
  }
  for (const key of Object.keys(element.properties)) {
    if (propertyToCssMap[element.type][key]) {
      styles[propertyToCssMap[element.type][key]] = getPropertyValueForEachCssProperty(propertyToCssMap[element.type][key], element.properties[key], element);
    } else {
      styles[key] = getPropertyValueForEachCssProperty(key, element.properties[key], element);
    }
  }

  return styles;
};
