import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";
import "./property-element-box.scss";
import { ToggleButton } from "primereact/togglebutton";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import imageCompression from "browser-image-compression";


const PropertyElementBox = observer(() => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);
  const selectedElement = appStructureTreeViewService.getCurrentElement();
 
  return (
    <div className="propertyElementBox">
      {selectedElement && appStructureTreeViewService.selectedElements.length === 1
        ? Object.keys(appStructureTreeViewService.getElementPropertyTypes(selectedElement)).filter(propertyType=>appStructureTreeViewService.doesPropertyPasessCondition(appStructureTreeViewService.getElementPropertyTypes(selectedElement)[propertyType], selectedElement.properties)).map((property, idx) => {
        
            const propertyDetail = appStructureTreeViewService.getElementPropertyTypes(selectedElement)[property];
            const currentElement = selectedElement;
            const currentElementIndex = appStructureTreeViewService.getCurrentElementIndex();

            return (
              <Property
                key={idx}
                property={propertyDetail}
                currentProperties={currentElement.properties}
                onElementalPropertyChange={appStructureTreeViewService.onElementalPropertyChange}
                currentElement={currentElement}
              />
            );
          })
        : ""}
    </div>
  );
});

const Property = observer(({ property, currentProperties, onElementalPropertyChange, currentElement }) => {
  const [element, setElement] = useState(null);

  useEffect(() => {
    switch (`${property.type} ${property.format}`) {
      case "decimal inputBox":
        setElement(
          <InputNumber
            className="input"
            value={currentProperties[property.name]}
            onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentElement)}
            mode="decimal"
            maxFractionDigits={2}
          />
        );
        break;
      case "colour inputBox":
        setElement(
          <div style={{ display: "flex", columnGap: "10px" }}> 
          
            <ColorPicker value={currentProperties[property.name]} onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: `#${e.value}` }, currentElement)} />
            <InputText className="input" value={currentProperties[property.name]} onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: `${e.target.value}` }, currentElement)} />
          </div>
        );
        break;
      case "boolean toggle":
        setElement(<ToggleButton checked={currentProperties[property.name]} id={property.name} onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentElement)} />);
        break;
      case "text select":
        setElement(
          <Dropdown
          className="input"
            value={currentProperties[property.name]}
            options={property.options.map((option, idx) => ({ label: option, value: option }))}
            onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentElement)}
          />
        );
        break;
      case "number inputBox":
        setElement(<InputNumber className="input" value={currentProperties[property.name]} onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentElement)} />);
        break;
      case "text textArea":
        setElement(<InputTextarea className="input" value={currentProperties[property.name]} onChange={(e) => onElementalPropertyChange({ ...currentProperties, [property.name]: e.target.value }, currentElement)} />);
        break;
      case "text image":
        setElement(
          <FileUpload
            name="demo[]"
            url="./upload"
            customUpload
            uploadHandler={(e) => {
              e.id = property.name;
              fileUpload(e);
            }}
            mode="basic"
          />
        );
        break;
    }
  }, [currentProperties]);

  const onChange = (event) => {
    let parsedValue = event.target.value;

    switch (event.target.type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      default:
        break;
    }

    onElementalPropertyChange({ ...currentProperties, [event.target.id]: parsedValue }, currentElement);
  };

  const fileUpload = async (e) => {
    const file = e.files[0];
    
    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    
      const compressedFile = await imageCompression(file, options);

    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      onElementalPropertyChange({ ...currentProperties, [e.id]: reader.result, fill: null,  imageChanged: true }, currentElement);
    };
    reader.readAsDataURL(compressedFile);
  };
  return (
    <div className={property.isDoubleSpan ? 'doubleSpan' : ''}>
      <label>{property.name}</label>
      {element}
    </div>
  );
});

export default PropertyElementBox;
