import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button, Toast } from "primereact";
import { GiPaintBrush } from "react-icons/gi";
import { BsFileEarmarkCodeFill } from "react-icons/bs";
import "./signup.scss";
import { BiUser } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";

const Signup = observer(({ authService, notificationService }) => {
  const [newUserDetails, setNewUserDetails] = useState({});
  const navigate = useNavigate();

  const accountTypeOptions = [
    {
      label: "designer",
      value: "cl9v2q9po000h09l4csr00b94",
    },
    {
      label: "developer",
      value: "cl9v2qup7001e09l4cmjy2u8t",
    },
  ];

  const onDetailsChange = (e) => {
    setNewUserDetails({
      ...newUserDetails,
      [e.target.id]: e.target.value,
    });
  };


  return (
    <div className="Signup">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          authService?.signup(navigate, newUserDetails);
        }}
      >
          <img src="/Studio K Logo.svg" alt="logo" className="logo" />

        <h1 className="header">
          Sign Up <BiUser />{" "}
        </h1>

        <InputText value={newUserDetails?.email ?? ""} onChange={onDetailsChange} id="email" placeholder="Email"></InputText>
        <InputText value={newUserDetails?.fullName ?? ""} onChange={onDetailsChange} id="fullName" placeholder="Full Name"></InputText>
        <Dropdown
          value={newUserDetails?.accountType}
          options={accountTypeOptions}
          itemTemplate={accountTypeOptionTemplate}
          placeholder="Account Type"
          onChange={(e) => setNewUserDetails({ ...newUserDetails, accountType: e.value })}
          id="accountType"
        ></Dropdown>
        <Button label="Signup" disabled={authService.authBusy} icon={<BiUser />} iconPos={"right"} type={"submit"}></Button>
        <Link to={"/signin"}>Already have an account? Signin here</Link>
      </form>
    </div>
  );
});

const accountTypeOptionTemplate = (option) => {
  return (
    <div className="accountType">
      {option.label === "designer" ? <GiPaintBrush /> : <BsFileEarmarkCodeFill />}
      <div>{option.label}</div>
    </div>
  );
};

export default Signup;
