import React, { useEffect } from "react";
import CanvasElement from "../canvas-element/canvas-element";
import "./canvas-group.scss";
import { observer } from "mobx-react";
import { Group, Rect } from "react-konva";
import { height } from "@mui/system";
import { useState } from "react";

const CanvasGroup = observer(({ group, appStructureTreeViewService }) => {
  const convertPropertyToProperFormat = (properties) => {
    const propertyClone = _.cloneDeep(properties);

    for (const property of Object.keys(propertyClone)) {
      const propertyType = appStructureTreeViewService.getGroupPropertyTypes(group)[property];

      if (propertyType) {
        const newImage = new Image();

        switch (`${propertyType.type} ${propertyType.format}`) {
          case "text image":
          case "text fillPatternImage":
            newImage.src = propertyClone[property];
            propertyClone[property] = newImage;
            break;
          default:
            break;
        }
      }
    }

    return propertyClone;
  };

  const getPropertyToPass = () => {
    const propertyToPass = {};
    for (const prop in group.properties) {
      if (appStructureTreeViewService.getGroupPropertyTypes()[prop] && !appStructureTreeViewService.getGroupPropertyTypes()[prop].avoidCanvasApply) {
        propertyToPass[prop] = group.properties[prop];
      }
    }

    if (appStructureTreeViewService.positionalProperties[group.id]) {
      propertyToPass["x"] = appStructureTreeViewService.positionalProperties[group.id].x;
      propertyToPass["y"] = appStructureTreeViewService.positionalProperties[group.id].y;
      propertyToPass["width"] = appStructureTreeViewService.positionalProperties[group.id].width;
      propertyToPass["height"] = appStructureTreeViewService.positionalProperties[group.id].height;
    }

    return propertyToPass;
  };

  return (
    <>
      {appStructureTreeViewService.mode === "developer" ? <Rect {...convertPropertyToProperFormat(getPropertyToPass())}></Rect> : ""}

      {group && appStructureTreeViewService
        ? appStructureTreeViewService.elements
            .filter((element) => !element?.isHidden && element.groupId === group.id)
            .map((element, idx) => (
              <CanvasElement
                key={idx}
                element={element}
                onChange={appStructureTreeViewService.onElementalPropertyChange}
                count={idx}
                onSelect={appStructureTreeViewService.selectElement}
                addElement={appStructureTreeViewService.addElementToSelection}
                appStructureTreeViewService={appStructureTreeViewService}
              />
            ))
        : null}
    </>
  );
});

export default CanvasGroup;
