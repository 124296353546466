import { convertPropertiesToCss } from "./convertPropertiesToCss";
import * as _ from 'lodash';

export const getStyledCssForPage = (page) => {
  const pageClone = _.cloneDeep(page);

    let stringValue = "";
    pageClone.type="page"
    const styles = convertPropertiesToCss(pageClone);

    for (const key of Object.keys(styles)) {
      stringValue = stringValue + `${key} : ${styles[key]}; \n`;
    }

    return stringValue;
  };