import React from "react";
import CanvasGroup from "../canvas-group/canvas-group";
import { observer } from "mobx-react";
import { Rect } from "react-konva";
import { element } from "prop-types";

const CanvasPage = observer(({ page, appStructureTreeViewService }) => {
  const convertPropertyToProperFormat = (properties) => {
    const propertyClone = _.cloneDeep(properties);

    for (const property of Object.keys(propertyClone)) {
      const propertyType = appStructureTreeViewService.getPagePropertyTypes(page)[property];

      if (propertyType) {
        const newImage = new Image();

        switch (`${propertyType.type} ${propertyType.format}`) {
          case "text image":
          case "text fillPatternImage":
            newImage.src = propertyClone[property];
            propertyClone[property] = newImage;
            break;
          default:
            break;
        }
      }
    }

    return propertyClone;
  };


  const getPropertyToPass = () => {
    const propertyToPass = {};
    for (const prop in page.properties) {
      if (appStructureTreeViewService.getPagePropertyTypes()[prop] && !appStructureTreeViewService.getPagePropertyTypes()[prop].avoidCanvasApply) {
        propertyToPass[prop] = page.properties[prop];
      }
    }

    if (appStructureTreeViewService.positionalProperties[page.id]) {
      propertyToPass["x"] = appStructureTreeViewService.positionalProperties[page.id].x;
      propertyToPass["y"] = appStructureTreeViewService.positionalProperties[page.id].y;
      propertyToPass["width"] = appStructureTreeViewService.positionalProperties[page.id].width;
      propertyToPass["height"] = appStructureTreeViewService.positionalProperties[page.id].height;
    }

    return propertyToPass;
  };

  return (
    <>
      {page ? <Rect id={page.id} {...convertPropertyToProperFormat(getPropertyToPass())} _useStrictMode></Rect> : ''}
      {page ? appStructureTreeViewService.groups.filter((group) => !group.isHidden && group.pageId === page.id).map((group, idx) => <CanvasGroup key={idx} group={group} appStructureTreeViewService={appStructureTreeViewService} />) : null}
    </>
  );
});

export default CanvasPage;
