import React from "react";
import { BiRectangle, BiCircle, BiText } from "react-icons/bi";
import {RiImageAddFill} from 'react-icons/ri';

import { CircleTool } from "./tool-classes/circle-tool";
import { ImageTool } from "./tool-classes/image-tool";
import { RectTool } from "./tool-classes/rect-tool";
import { TextTool } from "./tool-classes/text-tool";

export const toolList = {
  Rect: {
    icon: <BiRectangle />,
    class: RectTool,
  },
  Circle: {
    icon: <BiCircle />,
    class: CircleTool,
  },
  text: {
    icon: <BiText />,
    class: TextTool
  },
  image: {
    icon: <RiImageAddFill />,
    class: ImageTool
  },
};
