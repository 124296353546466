import React, { useEffect, useState } from "react";
import "./App.scss";
import CanvasStage from "./components/canvas-stage/canvas-stage";
import { LeftMenu } from "./components/left-menu/left-menu";
import { AppStructureTreeViewContext } from "./contexts/app-structure-tree-view-context";
import { ToolList } from "./components/tool-list/tool-list";
import PropertyBox from "./components/property-box/property-box";
import { observer } from "mobx-react";
import "primereact/resources/themes/md-dark-deeppurple/theme.css";
import { Button } from "primereact/button";
import axios from "axios";
import download from "downloadjs";
import { Toolbar } from "primereact/toolbar";
import DomPage from "./components/dom-page/dom-page";
import { useNavigate } from "react-router-dom";
import 'primereact/resources/primereact.min.css';
import { PrimeReactProvider } from 'primereact/api';

const App = observer(({ appStructureTreeViewService, authService, notificationService }) => {
  const [originalOrigin, setOriginalOrigin] = useState({ x: 0, y: 0, untouched: true });
  const [showDom, setShowDom] = useState(false);
  
  const [savingProject, setSavingProject] = useState(false);

  const navigate = useNavigate();

  const onKeyDown = async (e) => {
    if (appStructureTreeViewService.selectedElements.length && e.code.toLowerCase() === "keyd" && e.ctrlKey) {
      e.preventDefault();
      appStructureTreeViewService.duplicateCurrentElements();
    }

    if(e.code.toLowerCase() === "keys" && e.ctrlKey){
      e.preventDefault();
      saveProject();
    }
  };

  const saveProject = async() =>{
    setSavingProject(true);
  }

  const sendDataToServer = async () => {
    const response = await axios({
      url: "http://localhost:4500/generateProjectZip",
      method: "POST",
      data: appStructureTreeViewService.getter("elementalTree"),
      responseType: "arraybuffer",
    });

    // download(response.data, "project.zip", "application/zip");

    return response.data;
  };



  return (
    <AppStructureTreeViewContext.Provider value={appStructureTreeViewService}>
        <PrimeReactProvider>
      <div className="App" onKeyDown={onKeyDown} tabIndex="0">
        {/* {["designer", "developer"].includes(appStructureTreeViewService.getMode()) ? "" : <ModeSelector />} */}

        {appStructureTreeViewService.mode === "developer" ? (
          <div
            style={{
              opacity: showDom ? 1 : 0,
              zIndex: showDom ? 100000 : -1,
            }}
          >
            <DomPage toggleDom={setShowDom} page={appStructureTreeViewService.getCurrentPage()} appStructureTreeViewService={appStructureTreeViewService} />
          </div>
        ) : (
          ""
        )}

        <Toolbar
          className="toolBar"
          left={
            <>
              <img src="/Studio K Logo.svg" className="logo" alt="logo" onClick={() => navigate("/dashboard")} />

            </>
          }
          right={
            appStructureTreeViewService.mode === "developer" ? (
              <>
                <Button className="p-mr-2" label="View Dom" onClick={() => setShowDom(true)} icon="pi pi-eye" iconPos="right"></Button>
                <Button label="Download Project" onClick={sendDataToServer} icon="pi pi-download" iconPos="right"></Button>
              </>
            ) : (
              <Button label="Save Project" onClick={saveProject} icon="pi pi-save" iconPos="right"></Button>
            )
          }
        />
        <div className="mainSection">
          <LeftMenu />
          <CanvasStage originalOrigin={originalOrigin} setOriginalOrigin={setOriginalOrigin} savingProject={savingProject} setSavingProject={setSavingProject} notificationService={notificationService}/>
          <div className="rightMenu">
            <ToolList />
            <PropertyBox appStructureTreeViewService={appStructureTreeViewService} currentProject = {appStructureTreeViewService.getter('currentProject')}/>
          </div>
        </div>
      </div>
      </PrimeReactProvider>
    </AppStructureTreeViewContext.Provider>
  );
});

export default App;
