import { observer } from "mobx-react";
import { Button, Card, InputText, ColorPicker, Checkbox } from "primereact";
import React, { useEffect, useState } from "react";
import "./add-project-form.scss";

const AddProjectForm = observer(({ dataService, authService, notificationService, toggleForm, retrieveProjects }) => {
  const [projectDetails, setProjectDetails] = useState({});

  const onDetailsChange = (e) => {
    setProjectDetails({
      ...projectDetails,
      [e.target.id]: e.target.value,
    });
  };

  const setProjectProperties = (property, value) => {
    setProjectDetails({
      ...projectDetails,
      [property]: value,
    });
  }

  const addProject = async () => {
    const addResponse = await dataService.dataRequest({
      tableId: "cl9v1capu000l09l87ldih91x",
      authToken: authService.user.authToken,
      url: "data/item/addItem",
      itemData: {
        'cl9v1e6yx000n09lccaf29eou': projectDetails.title,
        'cl9v1fhxz001o09l9e9ln92ii': '{}',
        'cl9v1rgow000j09ju1wxpd5f2': authService.user.userId,
        'cl9wfhqx1000j09mideyu5e28': projectDetails.colour,
        'claabvrg8000j08kz89qi7m1b': projectDetails.isPublic,
        'clab0zrfu000j08jr53jnce3t': projectDetails.isTemplate,
        'cl9zi3ga8000j0ajk04wxcy6e': JSON.stringify([]),
        'cl9zibztk000n09ia2fsn62fl': JSON.stringify([]),
        'cl9zicaoe000i09l852ha1hgh': JSON.stringify([])
      },
    });

    if (addResponse.status === 1) {
      toggleForm();
      retrieveProjects();
    }
  };

  return (
    <Card className="AddProjectForm">
      <h5 className="headerSmall" style={{ margin: 0 }}>Add a project</h5>
      <InputText onChange={(e) => onDetailsChange(e)} placeholder="Project title" id="title" />
      <span>Project Colour</span>
      <ColorPicker value={projectDetails.colour} onChange={(e) => setProjectDetails({ ...projectDetails, colour: e.value })} />

      <div className="checkbox-group">
        <Checkbox id="isPublic" onChange={(e) => setProjectProperties('isPublic', e.checked)} checked={projectDetails.isPublic}></Checkbox>
        <label htmlFor="isPublic" className="p-checkbox-label">
          Is Public?
        </label>
      </div>

      <div className="checkbox-group">
        <Checkbox id="isTemplate" onChange={(e) => setProjectProperties('isTemplate', e.checked)} checked={projectDetails.isTemplate}></Checkbox>
        <label htmlFor="isTemplate" className="p-checkbox-label">
          Is Template?
        </label>
      </div>

      <div className="buttonContainer">
        <Button onClick={addProject} label={"Submit"} />
        <Button onClick={toggleForm} label={"Cancel"} style={{ backgroundColor: 'var(--red-500)', border: 'none' }} />
      </div>
    </Card>
  );
});

export default AddProjectForm;
