import { observable, makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import WebFont from "webfontloader";
import cuid from "cuid";
import { rectPropertyTypes } from "../components/tool-list/properties/elements/rect";
import { circlePropertyTypes } from "../components/tool-list/properties/elements/circle";
import { textPropertyTypes } from "../components/tool-list/properties/elements/text";
import { imagePropertyTypes } from "../components/tool-list/properties/elements/image";
import { groupProperties } from "../components/tool-list/properties/group-properties";

import { pageProperties } from "../components/tool-list/properties/page-properties";

export class AppStructureTreeViewService {
  currentProject;
  projectThumbnail;
  propertiesArray = [];
  pages = [];
  groups = [];
  elements = [];
  selectedElements = [];
  appVariables = {};
  currentPageId = null;
  currentGroupId = null;
  currentElementId = null;
  showPageProperties = false;
  showGroupProperties = false;
  fontLoader = WebFont;
  availableFonts = [];
  syncPropertyPipeLine = [];
  positionalProperties = {};

  mode = "designer";

  //services
  dataService;
  authService;

  pageAttributeIdMap = {
    id: "_id",
    name: "cl9wrqfis000q09i9huyjh5e6",
    isHidden: "cl9wrsugg000u09jk3c0vepck",
    properties: "cl9wrtwtf000i09ma1jpj53gb",
  };

  groupAttributeIdMap = {
    id: "_id",
    name: "cl9wrwrnd000j09k0h8wg5b4o",
    isHidden: "cl9wrx6y8005p09i9ccd26613",
    properties: "cl9wrxzpi008309ma38o4403c",
    pageId: "cl9ws1t91000j09jyfsd3dgo5",
  };

  elementAttributeIdMap = {
    id: "_id",
    name: "cl9ws7pi300es09jt7m6megzk",
    isHidden: "cl9wsc8db008709jy7qesgwqx",
    properties: "cl9wrxzpi008309ma38o4403c",
    pageId: "cl9wsdhqu00c309mmdak14w9o",
    groupId: "cl9wse7fb00ft09mahsf985ru",
    parentType: "cl9wsexia00c909jq1eoc664y",
    parentId: "cl9wsf9ey00cy09jyhwzo2t4g",
  };

  constructor(dataService, authService) {
    makeAutoObservable(this);

    this.dataService = dataService;
    this.authService = authService;
    this.fontLoader.load({
      google: {
        families: ["Roboto", "Pacifico", "Hectiva", "Cormorant Garamond", "Lato", "Open Sans", "Montserrat", "Oswald", "Source Sans Pro", "Slabo", "Raleway", "PT Sans"],
      },
    });
  }

  replaceAttrNameWithId = (data, dataType) => {
    const returnData = {};
    let mapToUse;
    switch (dataType) {
      case "element":
        mapToUse = this.elementAttributeIdMap;
        return;
      case "group":
        mapToUse = this.groupAttributeIdMap;
        return;
      case "page":
        mapToUse = this.elementAttributeIdMap;
        return;
      default:
        mapToUse = {};
    }

    for (const key of Object.keys(data)) {
      returnData[mapToUse[key]] = data[key];
    }

    return returnData;
  };

  syncProject = async () => {
    const pageArrayClone = _.cloneDeep(this.pages);
    const groupArrayClone = _.cloneDeep(this.groups);
    const elementArrayClone = _.cloneDeep(this.elements);

    console.log(this.currentProject);

    const syncedProject = await this.dataService.dataSocketRequest({
      action: "updateItem",
      tableId: "cl9v1capu000l09l87ldih91x",
      itemId: this.currentProject._id,
      itemData: {
        cl9zi3ga8000j0ajk04wxcy6e: JSON.stringify(
          pageArrayClone.map((page) => {
            delete page.properties;
            return page;
          })
        ),
        cl9zibztk000n09ia2fsn62fl: JSON.stringify(
          groupArrayClone.map((group) => {
            delete group.properties;
            return group;
          })
        ),
        cl9zicaoe000i09l852ha1hgh: JSON.stringify(
          elementArrayClone.map((element) => {
            delete element.properties;
            return element;
          })
        ),
        cl9v1e6yx000n09lccaf29eou: this.currentProject.title,
        cl9wfhqx1000j09mideyu5e28: this.currentProject.colour,
        cl9v1rgow000j09ju1wxpd5f2: this.authService.user.userId,
        cla7f0pvr000j08mf6bl52jg5: this.projectThumbnail,
        cl9v1fhxz001o09l9e9ln92ii: "{}",
      },
    });

    return syncedProject;
  };

  removeProject = async (project) => {
    const removeProject = await this.dataService.dataSocketRequest(
      {
        action: "removeItem",
        tableId: "cl9v1capu000l09l87ldih91x",
        authToken: this.authService.user.authToken,
        itemId: project._id,
      },
      (data, requestId) => {
        if (data.status === 1) {
          console.log("project removed");
        }
      }
    );

    return removeProject;
  };

  setCurrentProject = async (project) => {
    this.setter("currentProject", project);
    await this.getPropertiesForCurrentProject();

    const projectPages = JSON.parse(project.cl9zi3ga8000j0ajk04wxcy6e ?? "[]");
    const projectGroups = JSON.parse(project.cl9zibztk000n09ia2fsn62fl ?? "[]");
    const projectElements = JSON.parse(project.cl9zicaoe000i09l852ha1hgh ?? "[]");

    this.setter("pages", this.attachEntityProperties(projectPages));
    this.setter("groups", this.attachEntityProperties(projectGroups));
    this.setter("elements", this.attachEntityProperties(projectElements));
    this.setter("projectThumbnail", project.cla7f0pvr000j08mf6bl52jg5 ?? "");

    this.currentPageId = this.pages[0]?.id;
  };

  attachEntityProperties = (arr) => {
    return arr.map((entity) => {
      const entityProperty = this.propertiesArray.find((property) => property.cla5bsl8w005508jt16xhbjre === entity.id);
      if (entityProperty) {
        entity.properties = JSON.parse(entityProperty.cla5btha6007308jt9c72hpq2);
        entity.propertyId = entityProperty._id;
      } else {
        entity.properties = {};
        entity.propertyId = undefined;
      }

      return entity;
    });
  };

  getPropertiesForCurrentProject = async () => {
    const res = await this.dataService.dataRequest(
      {
        url: "data/item/retrieveItemsFromFilter",
        tableId: "cla5br52z001808js1ro04d3h",
        authToken: this.authService.user.authToken,
        itemFilter: {
          orPredicates: [
            {
              andPredicates: [
                {
                  attributeId: "cla5cg7r6000j07l24di96yn3",
                  operator: "EQ",
                  values: [this.currentProject._id],
                },
              ],
            },
          ],
        },
      },
      true
    );

    if (res.status === 1) {
      this.propertiesArray = res.data;
    }
  };

  setter = (propertyName, value) => {
    this[propertyName] = value;
  };

  getter = (propertyName) => {
    return this[propertyName];
  };

  setMode = (value) => {
    this.mode = value;
  };

  getMode = () => {
    return this.mode;
  };

  changePage = (newPage) => {
    this.currentPageId = newPage.id;
    this.currentGroupId = null;
    this.selectedElements = [];
  };

  changeGroup = (newGroup) => {
    this.currentGroupId = newGroup.id;

    this.selectedElements = this.elements.filter((element) => element.parentType === "group" && element.parentId === newGroup.id);
  };

  getPageById = (pageId) => {
    return this.pages.find((thisPage) => thisPage.id === pageId);
  };
  getCurrentPage = () => {
    return this.pages.find((page) => page.id === this.currentPageId);
  };

  getGroupById = (groupId) => {
    return this.groups.find((thisGroup) => thisGroup.id === groupId);
  };

  getCurrentGroup = () => {
    return this.groups.find((group) => group.id === this.currentGroupId);
  };

  getNewGroupName = () => {
    if (!this.groups.length) {
      return `Group ${0}`;
    }

    const lastGroup = this.groups[this.groups.length - 1];

    return `Group ${Number(lastGroup.name.slice(lastGroup.name.length - 1)) + 1}`;
  };

  getGroupElementsById = (groupId) => {
    return this.elements.find((element) => element.groupId === groupId);
  };

  getCurrentGroupElements = () => {
    return this.elements.find((element) => element.groupId === this.currentGroupId);
  };

  addToPages = (newPageName) => {
    const newPage = {
      id: cuid(),
      name: newPageName,
      isHidden: false,
      properties: {
        width: 1928,
        height: 1200,
        fill: "#ffffff",
      },
    };

    this.pages = this.pages.concat([newPage]);

    this.dataService.dataSocketRequest({
      action: "addItem",
      tableId: "cla5br52z001808js1ro04d3h",
      itemData: {
        cla5btha6007308jt9c72hpq2: JSON.stringify(newPage.properties ?? {}),
        cla5cg7r6000j07l24di96yn3: this.currentProject._id,
        cla5bsl8w005508jt16xhbjre: newPage.id,
        cla5brpd5000j08jtclii8s0o: "page",
      },
    });

    this.syncProject();
  };

  addPages = (newPages) => {
    this.pages = this.pages.concat(newPages);
  };

  addGroupToCurrentPage = (groupName) => {
    if ([null, undefined].includes(this.currentPageId)) {
      return;
    }

    const pageExists = this.getCurrentPage();

    if (!pageExists) {
      return;
    }

    const newGroup = {
      id: cuid(),
      pageId: pageExists.id,
      pageName: pageExists.name,
      name: groupName,
      isHidden: false,
      properties: {},
    };

    this.groups = this.groups.concat([newGroup]);

    this.currentGroupId = newGroup.id;

    this.dataService.dataSocketRequest({
      action: "addItem",
      tableId: "cla5br52z001808js1ro04d3h",
      itemData: {
        cla5btha6007308jt9c72hpq2: JSON.stringify(newGroup.properties ?? {}),
        cla5cg7r6000j07l24di96yn3: this.currentProject._id,
        cla5bsl8w005508jt16xhbjre: newGroup.id,
        cla5brpd5000j08jtclii8s0o: "group",
      },
    });

    this.syncProject();
  };

  addGroups = (groups) => {
    this.groups = this.groups.concat(groups);
  };

  addElementToCurrentGroup = (element) => {
    const elementToAdd = element;

    if ([null, undefined].includes(this.currentPageId) || [null, undefined].includes(this.currentGroupId)) {
      return;
    }

    const pageExists = this.getCurrentPage();

    if (!pageExists) {
      return;
    }

    const currentGroup = this.getCurrentGroup();
    elementToAdd.groupId = currentGroup.id;
    elementToAdd.groupName = currentGroup.name;
    elementToAdd.pageName = currentGroup.pageName;
    elementToAdd.pageId = currentGroup.pageId;
    elementToAdd.parentType = "group";
    elementToAdd.parentId = currentGroup.id;
    elementToAdd.isHidden = false;
    elementToAdd.name = element.type + this.elements.length;

    this.elements = this.elements.concat([{ ...elementToAdd }]);

    this.selectedElements = [elementToAdd];

    this.dataService.dataSocketRequest({
      action: "addItem",
      tableId: "cla5br52z001808js1ro04d3h",
      itemData: {
        cla5btha6007308jt9c72hpq2: JSON.stringify(elementToAdd.properties ?? {}),
        cla5cg7r6000j07l24di96yn3: this.currentProject._id,
        cla5bsl8w005508jt16xhbjre: elementToAdd.id,
        cla5brpd5000j08jtclii8s0o: "element",
      },
    });

    this.syncProject();
  };

  addElements = (elements) => {
    this.elements = this.elements.concat(elements);
  };

  // addPropertyUpdateActionToPipeLine = (data) => {

  //   const preExistingAddActionIndex = this.syncPropertyPipeLine.findIndex((p) => p.item === data.id && p.action === "update");

  //   if (preExistingAddActionIndex !== -1) {
  //     this.syncPropertyPipeLine[preExistingAddActionIndex] = {
  //       item: data.id,
  //       action: "update",
  //       callback: () =>
  //         this.dataService.dataRequest({
  //           url: "data/item/updateItem",
  //           tableId: "cla5br52z001808js1ro04d3h",
  //           authToken: this.authService.user.authToken,
  //           itemData: {
  //             cla5btha6007308jt9c72hpq2: JSON.stringify(data.properties ?? {}),
  //           },
  //           itemId: data.propertyId,
  //         }, true),
  //     };
  //   } else {
  //     this.syncPropertyPipeLine.push({
  //       item: data.id,
  //       action: "update",
  //       callback: () =>
  //         this.dataService.dataRequest({
  //           url: "data/item/updateItem",
  //           tableId: "cla5br52z001808js1ro04d3h",
  //           authToken: this.authService.user.authToken,
  //           itemData: {
  //             cla5btha6007308jt9c72hpq2: JSON.stringify(data.properties ?? {}),
  //           },
  //           itemId: data.propertyId,
  //         }, true),
  //     });
  //   }

  // };

  // addPropertyAddActionToPipeLine = (data, type) => {
  //   const preExistingAddActionIndex = this.syncPropertyPipeLine.findIndex((p) => p.item === data.id && p.action === "add");

  //   if (preExistingAddActionIndex !== -1) {
  //     this.syncPropertyPipeLine[preExistingAddActionIndex] = {
  //       item: data.id,
  //       action: "add",
  //       callback: () =>

  //     };
  //   } else {
  //     this.syncPropertyPipeLine.push({
  //       item: data.id,
  //       action: "add",
  //       callback: () =>
  //         this.dataService.dataRequest({
  //           url: "data/item/addItem",
  //           tableId: "cla5br52z001808js1ro04d3h",
  //           authToken: this.authService.user.authToken,
  //           itemData: {
  //             cla5btha6007308jt9c72hpq2: JSON.stringify(data.properties ?? {}),
  //             cla5cg7r6000j07l24di96yn3: this.currentProject._id,
  //             cla5bsl8w005508jt16xhbjre: data.id,
  //             cla5brpd5000j08jtclii8s0o: type
  //           },
  //         }, true),
  //     });
  //   }
  // };

  // addPropertyActionToPipeLine = (data, type = null) => {
  //   if (data.propertyId) {
  //     this.addPropertyUpdateActionToPipeLine(data);
  //   } else {
  //     this.addPropertyAddActionToPipeLine(data, type);
  //   }
  // };

  onPagePropertyChange = (newProperties, page) => {
    const newPagesList = _.cloneDeep(this.pages);

    const pageIndex = this.pages.findIndex((thisPage) => thisPage.id === page.id);

    newPagesList[pageIndex] = {
      ...newPagesList[pageIndex],
      properties: {
        ...newProperties,
      },
    };

    this.pages = newPagesList;

    this.dataService.dataSocketRequest({
      action: "updateItem",
      tableId: "cla5br52z001808js1ro04d3h",
      itemId: newPagesList[pageIndex].propertyId,
      itemData: {
        cla5btha6007308jt9c72hpq2: JSON.stringify(newPagesList[pageIndex].properties ?? {}),
      },
    });

    this.syncProject();
  };

  onGroupPropertyChange = (newProperties, group) => {
    const newGroupsList = _.cloneDeep(this.groups);

    const groupIndex = this.groups.findIndex((thisGroup) => thisGroup.id === group.id);

    newGroupsList[groupIndex] = {
      ...newGroupsList[groupIndex],
      properties: {
        ...newProperties,
      },
    };

    this.groups = newGroupsList;

    this.dataService.dataSocketRequest({
      action: "updateItem",
      tableId: "cla5br52z001808js1ro04d3h",
      itemId: newGroupsList[groupIndex].propertyId,
      itemData: {
        cla5btha6007308jt9c72hpq2: JSON.stringify(newGroupsList[groupIndex].properties ?? {}),
      },
    });
  };

  onElementalPropertyChange = (newProperties, element) => {
    const newElementsList = _.cloneDeep(this.elements);

    const elementIndex = this.elements.findIndex((thisElement) => thisElement.id === element.id);

    const selectedElementIndex = this.selectedElements.findIndex((thisElement) => thisElement.id === element.id);

    newElementsList[elementIndex] = {
      ...newElementsList[elementIndex],
      properties: {
        ...newProperties,
      },
    };

    this.selectedElements[selectedElementIndex ?? 0] = {
      ...newElementsList[elementIndex],
      properties: {
        ...newProperties,
      },
    };

    this.elements = newElementsList;

    this.dataService.dataSocketRequest({
      action: "updateItem",
      tableId: "cla5br52z001808js1ro04d3h",
      itemId: newElementsList[elementIndex].propertyId,
      itemData: {
        cla5btha6007308jt9c72hpq2: JSON.stringify(newElementsList[elementIndex].properties ?? {}),
      },
    });
  };

  removeSelectedElements = () => {
    this.elements = this.elements.filter((element) => !this.selectedElements.map((element) => element.id).includes(element.id));
    this.selectedElements = [];
  };

  duplicateCurrentElements = () => {
    const elementsToAttach = [];
    for (const selectedElement of this.selectedElements) {
      const currentElement = _.cloneDeep(selectedElement);
      currentElement.id = cuid();
      currentElement.name = currentElement.type + this.elements.length;
      currentElement.propertyId = undefined;
      elementsToAttach.push(currentElement);

      this.dataService.dataSocketRequest({
        action: "addItem",
        tableId: "cla5br52z001808js1ro04d3h",
        itemData: {
          cla5btha6007308jt9c72hpq2: JSON.stringify(currentElement.properties ?? {}),
          cla5cg7r6000j07l24di96yn3: this.currentProject._id,
          cla5bsl8w005508jt16xhbjre: currentElement.id,
          cla5brpd5000j08jtclii8s0o: "element",
        },
      }); 
    }

    this.elements = this.elements.concat(elementsToAttach);
    this.syncProject();
  };

  selectElement = (element, skipChildren) => {
    this.selectedElements = [].concat([element]).concat();

    if (!skipChildren) {
      this.selectedElements = this.selectedElements.concat(this.elements.filter((thisElement) => thisElement.parentType === "element" && thisElement.parentId === element.id));
    }
  };

  addElementToSelection = (element) => {
    this.selectedElements = this.selectedElements.concat([element]);
  };

  addElementsToSelection = (elements) => {
    this.selectedElements = this.selectedElements.concat(elements);
  };

  deselectElement = (deselectedElement) => {
    this.selectedElements = this.selectedElements.filter((element) => element.id !== deselectedElement.id);
  };

  deselectAllElements = () => {
    this.selectedElements = [];
  };

  getCurrentElement = () => {
    if (this.selectedElements.length !== 1) {
      return;
    }

    return this.selectedElements[0];
  };

  getCurrentElementIndex = () => {
    if (this.selectedElements.length !== 1) {
      return;
    }

    const selectedElement = this.selectedElements[0];

    return this.elements.findIndex((element) => element.id === selectedElement.id);
  };

  moveSelectedElementsOneLayerAbove = () => {
    for (const selectedElement of this.selectedElements) {
      const indexOfSelectedElement = this.elements.findIndex((element) => element.id === selectedElement.id);

      let insertIndex;

      if (indexOfSelectedElement === this.elements.length - 1) {
        insertIndex = indexOfSelectedElement;
      } else {
        insertIndex = indexOfSelectedElement + 1;
      }

      const otherElements = _.cloneDeep(this.elements.filter((element) => element.id !== selectedElement.id));
      const elements = _.cloneDeep(this.elements.filter((element) => element.id === selectedElement.id));

      otherElements.splice(insertIndex, 0, ...elements);

      this.elements = otherElements;
    }
    this.syncProject();
  };

  moveSelectedElementsOneLayerBelow = () => {
    for (const selectedElement of this.selectedElements) {
      const indexOfSelectedElement = this.elements.findIndex((element) => element.id === selectedElement.id);

      let insertIndex;

      if (indexOfSelectedElement === 0) {
        insertIndex = indexOfSelectedElement;
      } else {
        insertIndex = indexOfSelectedElement - 1;
      }

      const otherElements = _.cloneDeep(this.elements.filter((element) => element.id !== selectedElement.id));
      const elements = _.cloneDeep(this.elements.filter((element) => element.id === selectedElement.id));

      otherElements.splice(insertIndex, 0, ...elements);

      this.elements = otherElements;
    }
    this.syncProject();
  };

  moveSelectedElementsToTop = () => {
    const page = this.pages[this.currentPageId];

    const pageExists = this.elementalTree.get(page);

    const currentGroupList = pageExists.groups;

    for (const selectedElement of this.selectedElements) {
      const groupIndex = currentGroupList.indexOf(currentGroupList.find((group) => group.id === selectedElement.groupId));

      let currentGroupElements = currentGroupList[groupIndex].elements;

      const otherElements = _.cloneDeep(currentGroupElements.filter((element) => element.id !== selectedElement.id));
      const elements = _.cloneDeep(currentGroupElements.filter((element) => element.id === selectedElement.id));

      otherElements.splice(otherElements.length - 1, 0, ...elements);

      currentGroupList[groupIndex].elements = otherElements;
    }

    this.elementalTree.set(page, {
      ...pageExists,
      groups: [...currentGroupList],
    });
    this.syncProject();
  };

  moveSelectedElementsToBottom = () => {
    const page = this.pages[this.currentPageId];

    const pageExists = this.elementalTree.get(page);

    const currentGroupList = pageExists.groups;

    for (const selectedElement of this.selectedElements) {
      const groupIndex = currentGroupList.indexOf(currentGroupList.find((group) => group.id === selectedElement.groupId));
      let currentGroupElements = currentGroupList[groupIndex].elements;

      const otherElements = _.cloneDeep(currentGroupElements.filter((element) => element.id !== selectedElement.id));
      const elements = _.cloneDeep(currentGroupElements.filter((element) => element.id === selectedElement.id));

      elements.splice(elements.length - 1, 0, ...otherElements);

      currentGroupList[groupIndex].elements = elements;
    }

    this.elementalTree.set(page, {
      ...pageExists,
      groups: [...currentGroupList],
    });

    this.syncProject();
  };

  getCurrentDataForTreeView = () => {
    const dataToReturn = [];

    for (let i = 0; i <= this.pages.length - 1; i++) {
      const page = this.pages[i];

      const pageData = {
        key: `${i}`,
        label: page.name,
        nodeType: "page",
        pageId: page.id,
        page: page,
        isHidden: page.isHidden,
        children: [],
        droppable: true,
      };

      const pageGroupList = this.groups.filter((group) => group.pageId === page.id);

      for (let j = 0; j <= pageGroupList.length - 1; j++) {
        const group = pageGroupList[j];

        const groupData = {
          key: `${i}-${j}`,
          label: group.name,
          nodeType: "group",
          groupId: group.id,
          group: group,
          children: [],
          isHidden: group.isHidden,
          draggable: true,
          droppable: true,
        };

        const groupElementList = this.elements.filter((element) => element.parentType === "group" && element.parentId === group.id);

        for (let k = 0; k <= groupElementList.length - 1; k++) {
          const element = groupElementList[k];

          const elementData = {
            key: `${i}-${j}-${k}`,
            label: element.name,
            nodeType: "element",
            elementId: element.id,
            element: element,
            children: [],
            isHidden: element.isHidden,
            icon: element.properties.treeIcon,
            className: "elementNode",
            droppable: true,
            draggable: true,
          };

          elementData.children = this.getChildrenForElementForTree(element, `${i}-${j}-${k}`);

          groupData.children.push(elementData);
        }

        pageData.children.push(groupData);
      }

      dataToReturn.push(pageData);
    }

    return dataToReturn;
  };

  getChildrenForElementForTree = (parentElement, oldKey) => {
    const arrayToReturn = [];

    //find element's immediate children
    const elements = this.elements.filter((element) => element.parentType === "element" && element.parentId === parentElement.id);

    for (let i = 0; i <= elements.length - 1; i++) {
      const element = elements[i];

      const elementData = {
        key: `${oldKey}-${i}`,
        label: element.name,
        nodeType: "element",
        elementId: element.id,
        element: element,
        children: [],
        isHidden: element.isHidden,
        icon: element.properties.treeIcon,
        className: "elementNode",
        droppable: true,
        draggable: true,
      };

      elementData.children = this.getChildrenForElementForTree(element, `${oldKey}-${i}`);

      arrayToReturn.push(elementData);
    }

    return arrayToReturn;
  };

  getChildrenForElement = (parentElementNode, currentGroup, currentPage, arrayToReturn = []) => {
    for (let i = 0; i <= parentElementNode.children.length - 1; i++) {
      const childrenElementNode = parentElementNode.children[i];

      const elementData = {
        id: childrenElementNode.elementId,
        name: childrenElementNode.label,
        pageId: currentPage.id,
        pageName: currentPage.name,
        groupId: currentGroup.id,
        groupName: currentGroup.name,
        parentType: "element",
        parentId: parentElementNode.element.id,
        isHidden: childrenElementNode.isHidden,
        type: childrenElementNode.element.type,
        properties: _.cloneDeep(childrenElementNode.element.properties),
      };

      arrayToReturn.push(elementData);

      this.getChildrenForElement(childrenElementNode, currentGroup, currentPage, arrayToReturn);
    }

    return arrayToReturn;
  };

  handleDragDrop = (tree) => {
    if (!this.checkTreeHierarchy(tree)) {
      return;
    }

    const data = this.extractDataFromTreeValue(tree);

    this.pages = data.newPageList;
    this.groups = data.newGroupList;
    this.elements = data.newElementsList;
    this.syncProject();
  };

  checkTreeHierarchy = (tree) => {
    for (const page of tree) {
      if (page.nodeType !== "page") {
        return false;
      }

      for (const group of page.children) {
        if (group.nodeType !== "group") {
          return false;
        }

        for (const element of group.children) {
          if (element.nodeType !== "element") {
            return false;
          }
        }
      }
    }

    return true;
  };

  extractDataFromTreeValue = (tree) => {
    const newPageList = [];
    const newGroupList = [];
    let newElementsList = [];

    for (const page of tree) {
      const newPageData = {
        id: page.pageId,
        name: page.label,
        isHidden: page.isHidden,
        properties: page.page.properties,
      };

      for (const group of page.children) {
        const newGroupData = {
          id: group.groupId,
          name: group.label,
          pageId: page.pageId,
          pageName: page.label,
          isHidden: group.isHidden,
          properties: group.group.properties,
        };

        for (const element of group.children) {
          const newElementData = {
            id: element.elementId,
            name: element.label,
            pageId: page.pageId,
            pageName: page.label,
            groupId: group.groupId,
            groupName: group.label,
            parentType: "group",
            parentId: group.groupId,
            isHidden: element.isHidden,
            type: element.element.type,
            properties: _.cloneDeep(element.element.properties),
          };

          newElementsList = newElementsList.concat([newElementData]);
          newElementsList = newElementsList.concat(this.getChildrenForElement(element, newGroupData, newPageData));
        }

        newGroupList.push(newGroupData);
      }

      newPageList.push(newPageData);
    }

    return {
      newPageList,
      newGroupList,
      newElementsList,
    };
  };

  isNullOrUndefined = (data) => {
    return [null, undefined].includes(data);
  };

  removeNode = (node) => {
    switch (node.nodeType.toLowerCase()) {
      case "page":
        this.removePage(node.page);
        break;
      case "group":
        this.removeGroup(node.group);
        break;
      case "element":
        this.removeElement(node.element);
        break;
      default:
        break;
    }
    this.syncProject();
  };

  toggleNode = (node) => {
    switch (node.nodeType.toLowerCase()) {
      case "page":
        this.togglePage(node.page);
        break;
      case "group":
        this.toggleGroup(node.group);
        break;
      case "element":
        this.toggleElement(node.element);
        break;
      default:
        break;
    }
  };

  togglePage = (page) => {
    const pageIndex = this.pages.findIndex((thisPage) => thisPage.id === page.id);

    this.pages[pageIndex] = { ...this.pages[pageIndex], isHidden: !this.pages[pageIndex].isHidden };

    if (this.currentPageId === this.pages[pageIndex].id) {
      this.currentPageId = null;
    }

    this.selectedElements = this.selectedElements.filter((selectedElement) => selectedElement.pageName !== page.name);
  };

  removePage = (page) => {
    this.pages = this.pages.filter((thisPage) => thisPage.id !== page.id);

    if (this.currentPageId === page.id) {
      this.currentPageId = null;
    }

    this.selectedElements = this.selectedElements.filter((selectedElement) => selectedElement.pageId !== page.id);
    this.syncProject();
  };

  toggleGroup = (group) => {
    const groupIndex = this.groups.findIndex((thisgroup) => thisgroup.id === group.id);

    this.groups[groupIndex] = { ...this.groups[groupIndex], isHidden: !this.groups[groupIndex].isHidden };

    if (group.id === this.currentGroupId) {
      this.currentGroupId = null;
    }

    this.selectedElements = this.selectedElements.filter((selectedElement) => selectedElement.groupId !== group.id);
  };

  removeGroup = (group) => {
    this.groups = this.groups.filter((thisGroup) => thisGroup.id !== group.id);

    if (group.id === this.currentGroupId) {
      this.currentGroupId = null;
    }

    this.selectedElements = this.selectedElements.filter((selectedElement) => selectedElement.groupId !== group.id);
    this.syncProject();
  };

  toggleElement = (element) => {
    const elementIndex = this.elements.findIndex((thisElement) => thisElement.id === element.id);

    this.elements[elementIndex] = { ...this.elements[elementIndex], isHidden: !this.elements[elementIndex].isHidden };

    this.selectedElements = this.selectedElements.filter((selectedElement) => selectedElement.id !== element.id);
  };

  removeElement = (element) => {
    this.elements = this.elements.filter((thisElement) => thisElement.id !== element.id && thisElement.parentId !== element.id);

    this.selectedElements = this.selectedElements.filter((selectedElement) => selectedElement.id !== element.id);
    this.syncProject();
  };

  getElementPropertyTypes = (element) => {
    switch (element.type) {
      case "rect":
        return rectPropertyTypes;
      case "image":
        return imagePropertyTypes;
      case "circle":
        return circlePropertyTypes;
      case "text":
        return textPropertyTypes;
    }
  };

  getGroupPropertyTypes = () => {
    return groupProperties;
  };

  getPagePropertyTypes = () => {
    return pageProperties;
  };

  doesPropertyPasessCondition = (propertyType, currentProperties) => {
    if (this.mode === "designer" && propertyType.developerOnly) {
      return false;
    }

    if (!propertyType.displayCondition) {
      return true;
    }

    for (const or of propertyType.displayCondition.ors) {
      for (const and of or.ands) {
        if (!currentProperties[and.property] || currentProperties[and.property] !== and.value) {
          break;
        }

        if (or.ands.indexOf(and) === or.ands.length - 1) {
          return true;
        }
      }
    }
    return false;
  };

  // applyInheritanceToAllNodes = () => {
  //   for (const group of this.groups) {
  //     const parentPage = this.pages.find((page) => page.id === group.pageId);

  //     const currentProperties = _.cloneDeep(group.properties);

  //     //first figure out positioning

  //     //parentDetails
  //     const parentPadding = parentPage.properties.padding ?? 0;
  //     const parentPaddingUnit = parentPage.properties.padding ?? 0;
  //     const parentDisplay = parentPage.properties.display;

  //   }

  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPaddingUnit = parentPage.properties.padding ?? 0;

  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPadding = parentPage.properties.padding ?? 0;

  //   if (parentPage) {
  //     if (parentPage.properties.display && parentPage.properties.display === "flex") {
  //     }
  //   }
  // };

  // applyInheritanceToElements = (group) => {
  //   const parentPage = this.pages.find((page) => page.id === group.pageId);

  //   const currentProperties = _.cloneDeep(group.properties);

  //   //parentDetails
  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPaddingUnit = parentPage.properties.padding ?? 0;

  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPaddingUnit = parentPage.properties.padding ?? 0;

  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPadding = parentPage.properties.padding ?? 0;
  //   const parentPadding = parentPage.properties.padding ?? 0;

  //   if (parentPage) {
  //     if (parentPage.properties.display && parentPage.properties.display === "flex") {
  //     }
  //   }
  // };
}
