import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";
import "./property-group-box.scss";
import { ToggleButton } from "primereact/togglebutton";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

const PropertyGroupBox = observer(() => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);
  const selectedGroup = appStructureTreeViewService.getCurrentGroup();

  return (
    <div className="propertyGroupBox">
      {selectedGroup
               ? Object.keys(appStructureTreeViewService.getGroupPropertyTypes()).filter(propertyType=>appStructureTreeViewService.doesPropertyPasessCondition(appStructureTreeViewService.getGroupPropertyTypes()[propertyType], selectedGroup.properties)).map((property, idx) => {

            const propertyDetail = appStructureTreeViewService.getGroupPropertyTypes()[property];
            const currentGroup = selectedGroup;

            return (
              <Property
                key={idx}
                property={propertyDetail}
                currentProperties={currentGroup.properties}
                onGroupalPropertyChange={appStructureTreeViewService.onGroupPropertyChange}
                currentGroup={currentGroup}
              />
            );
          })
        : ""}
    </div>
  );
});

const Property = observer(({ property, currentProperties, onGroupalPropertyChange, currentGroup }) => {
  const [element, setGroup] = useState(null);

  useEffect(() => {
    switch (`${property.type} ${property.format}`) {
      case "decimal inputBox":
        setGroup(
          <InputNumber
            className="input"
            value={currentProperties[property.name]}
            onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentGroup)}
            mode="decimal"
            maxFractionDigits={2}
          />
        );
        break;
      case "colour inputBox":
        setGroup(
          <div style={{ display: "flex", columnGap: "10px" }}> 
            <ColorPicker value={currentProperties[property.name]} onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: `#${e.value}` }, currentGroup)} />
            <InputText className="input" value={currentProperties[property.name]} onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: `${e.target.value}` }, currentGroup)} />
          </div>
        );
        break;
      case "boolean toggle":
        setGroup(<ToggleButton checked={currentProperties[property.name]} id={property.name} onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentGroup)} />);
        break;
      case "text select":
        setGroup(
          <Dropdown
          className="input"
            value={currentProperties[property.name]}
            options={property.options.map((option, idx) => ({ label: option, value: option }))}
            onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentGroup)}
          />
        );
        break;
      case "number inputBox":
        setGroup(<InputNumber className="input" value={currentProperties[property.name]} onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: e.value }, currentGroup)} />);
        break;
      case "text textArea":
        setGroup(<InputTextarea className="input" value={currentProperties[property.name]} onChange={(e) => onGroupalPropertyChange({ ...currentProperties, [property.name]: e.target.value }, currentGroup)} />);
        break;
      case "text image":
        setGroup(
          <FileUpload
            name="demo[]"
            url="./upload"
            customUpload
            uploadHandler={(e) => {
              e.id = property.name;
              fileUpload(e);
            }}
            mode="basic"
          />
        );
        break;
    }
  }, [currentProperties]);

  const onChange = (event) => {
    let parsedValue = event.target.value;

    switch (event.target.type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      default:
        break;
    }

    onGroupalPropertyChange({ ...currentProperties, [event.target.id]: parsedValue }, currentGroup);
  };

  const fileUpload = (e) => {
    const file = e.files[0];
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      onGroupalPropertyChange({ ...currentProperties, [e.id]: reader.result, fill: null }, currentGroup);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <label>{property.name}</label>
      {element}
    </div>
  );
});

export default PropertyGroupBox;
