import cuid from "cuid";

export class TextTool {
  

  type = "text";
  id = cuid();
  properties = {
    isComponent: false,
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    fill: "red",
    text: "Hello"
  };

}
