import {w3cwebsocket} from 'websocket';
import cuid from "cuid";
import axios from "axios";


export class SocketService{
  writableSocket;

  createWritableSocket = (user) =>{
    this.writableSocket = new WritableSocket((data, requestId)=>{
        console.log(data, requestId);
        console.log('default Handler');
    }, user);
  }
}

export class WritableSocket {

    writeId;
    pending;
    user;
    ws;
  
    requestStatuses = {}
    requestHandlers = {}
  
    defaultHandler = undefined
  
    constructor(defaultHandler, user) {
      this.defaultHandler = defaultHandler
      this.user = user;
      this.writeId = cuid()
  
      this.connect().then(() => {
        return;
      });
    }
  
    onConnect = () => {
      this.pending = false;
    };
  
    ondisconnect = event => {
  
      if (event.reason === 'Going away') {
        this.connect();
      } else {
        this.pending = true;
      }
    };
  
    sendMessage(message,
      forget,
      handler) {
      const requestId = cuid()
      const m = message;
  
      if (forget) {
        m.forget = true
      } else {
        m.requestId = requestId
        this.requestStatuses[requestId] = false
        if (handler) {
          this.requestHandlers[requestId] = handler
        }
      }

      console.log(m);
  
      this.ws.send(JSON.stringify(m))
  
      if (forget) {
        return undefined
      }
      return requestId
    }
  
    getRequestStatus(requestId) {
      return this.requestStatuses[requestId]
    }
  
    async connect() {
      const socketAddress = await this.getWritableSocketAddress();
      const ws = this.ws = new w3cwebsocket(socketAddress, undefined, undefined);
      // ws.onclose = this.onClose;
      ws.onclose = e => {
        if (!e.wasClean) {
          this.pending = true;
        }
      };
      ws.onmessage = this.messageReceived;
      ws.onopen = this.onConnect;
      ws.onclose = this.ondisconnect;
      ws.onerror = error => {
        this.pending = true;
      };
    }
  
    close() {
      this.ws.close()
    }
  
    /**
     * remove statuses and handlers for requests that have been completed to reclaim memory
     */
    cleanup() {
      for (const requestId in this.requestStatuses) {
        if (this.requestStatuses[requestId] === true) {
          if (this.requestHandlers[requestId]) {
            delete this.requestHandlers[requestId]
          }
          delete this.requestStatuses[requestId]
        }
      }
    }
  
  
    messageReceived = event => {
      if (event.data) {
        const data = JSON.parse(event.data);
        if (data.requestId && this.requestHandlers[data.requestId]) {
  
          const res = this.requestHandlers[data.requestId](data, data.requestId)
          delete this.requestHandlers[data.requestId]
          this.requestStatuses[data.requestId] = true
        } else if (this.defaultHandler) {
          return this.defaultHandler(data, data.requestId)
        }
      }
    };
  
  
    async getWritableSocketAddress() {
      const postObj = {
        postData: {
          authToken: this.user.authToken,
          culture: 'en-AU',
          url: window.href,
          writeId: this.writeId
        },
      };
      const res = await axios.post('https://api-prd-ap-southeast-2-data.kzen8.com/data/websocket/token', postObj, false, true);
      const { secToken } = res.data

      console.log(res);
  
      // secure token is preferred, but if it fails use the auth token
      return `wss://api-prd-ap-southeast-2-push.kzen8.com?${secToken ? 'secToken' : 'token'}=${secToken}`;
    }
  
  }