import React, { useContext, useRef, useState } from "react";
import { LeftMenuList } from "../left-menu-list/left-menu-list";
import "./left-menu.scss";
import { observer } from "mobx-react";
import { Toast } from "primereact/toast";

//contexts import
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";

//material ui components import
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

export const LeftMenu = observer(() => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);

  const [newPageName, setNewPageName] = useState(null);
  const toast = useRef(null);

  const buttonClick = () => {
    if (!appStructureTreeViewService.pages.includes(newPageName)) {
      appStructureTreeViewService.addToPages(newPageName);
    } else {
      toast.current.show({ severity: "error", summary: "Alert", detail: "Duplicate page name is not allowed." });
    }
  };

  return (
    <div className="leftMenu">
      <Toast ref={toast} sticky />
      <SwipeableDrawer anchor={"left"} open={true} variant="persistent">
        <div className="addPageSection">
          <label>Add a page</label>
          <div className="form">
            <InputText onChange={(e) => setNewPageName(e.target.value)} placeholder="Page name" />
            <Button icon="pi pi-plus" disabled={!newPageName} onClick={buttonClick} />
          </div>
        </div>

        <LeftMenuList />
      </SwipeableDrawer>
    </div>
  );
});
