import cuid from "cuid";

export class ImageTool {
  type = "image";
  id = cuid();
  properties = {
    isComponent: false,
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    treeIcon: "pi pi-fw pi-image"
  };

}
