import React, { useContext, useRef } from "react";
import { observer } from "mobx-react";
import { toolList } from "./tool-list-variable";
import "./tool-list.scss";
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";
import { RiDeleteBin6Line } from "react-icons/ri";
import {BiLayerMinus, BiLayerPlus} from "react-icons/bi";
import {Toast} from 'primereact/toast';
//material component import

export const ToolList = observer(function () {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);
  const toast = useRef(null);
  
  const toolClick = (elementClass) => {
    if(!appStructureTreeViewService.isNullOrUndefined(appStructureTreeViewService.currentPageId) && !appStructureTreeViewService.isNullOrUndefined(appStructureTreeViewService.currentGroupId))
    {
      appStructureTreeViewService.addElementToCurrentGroup(new elementClass());
    }
    else
    {
      toast.current.show({severity: 'error', summary: 'Alert', detail: 'Select a page/group first.'});
    }

    
  };

  return (
    <div className="toolList card">
      <Toast ref={toast} />
      {Object.keys(toolList).map(function (tool, idx) {
        return (
          <div
            key={idx}
            className="tool"
            onClick={function () {
              toolClick(toolList[tool].class);
            }}
          >
            {toolList[tool].icon}
          </div>
        );
      })}


      <div
        className="tool"
        onClick={() => {
          appStructureTreeViewService.moveSelectedElementsOneLayerAbove();
        }}
      >
        <BiLayerPlus />
      </div>

      <div
        className="tool"
        onClick={() => {
          appStructureTreeViewService.moveSelectedElementsOneLayerBelow();
        }}
      >
        <BiLayerMinus />
      </div>

      <div
        className="tool"
        onClick={() => {
          appStructureTreeViewService.removeSelectedElements();
        }}
      >
        <RiDeleteBin6Line />
      </div>
    </div>
  );
});
