import { observer } from "mobx-react";
import React, { useContext, useEffect, useState } from "react";
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";
import "./property-page-box.scss";
import { ToggleButton } from "primereact/togglebutton";
import { ColorPicker } from "primereact/colorpicker";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

const PropertyPageBox = observer(() => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);
  const selectedPage = appStructureTreeViewService.getCurrentPage();

   return (
    <div className="propertyPageBox">
      {selectedPage
         ? Object.keys(appStructureTreeViewService.getPagePropertyTypes()).filter(propertyType=>appStructureTreeViewService.doesPropertyPasessCondition(appStructureTreeViewService.getPagePropertyTypes()[propertyType], selectedPage.properties)).map((property, idx) => {

            const propertyDetail = appStructureTreeViewService.getPagePropertyTypes()[property];
            const currentPage = selectedPage;

            return (
              <Property
                key={idx}
                property={propertyDetail}
                currentProperties={currentPage.properties}
                onPagePropertyChange={appStructureTreeViewService.onPagePropertyChange}
                currentPage={currentPage}
              />
            );
          })
        : ""}
    </div>
  );
});

const Property = observer(({ property, currentProperties, onPagePropertyChange, currentPage }) => {
  const [element, setPage] = useState(null);

  useEffect(() => {
    switch (`${property.type} ${property.format}`) {
      case "decimal inputBox":
        setPage(
          <InputNumber
            className="input"
            value={currentProperties[property.name]}
            onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: e.value }, currentPage)}
            mode="decimal"
            maxFractionDigits={2}
          />
        );
        break;
      case "colour inputBox":
        setPage(
          <div style={{ display: "flex", columnGap: "10px" }}> 
            <ColorPicker value={currentProperties[property.name]} onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: `#${e.value}` }, currentPage)} />
            <InputText className="input" value={currentProperties[property.name]} onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: `${e.target.value}` }, currentPage)} />
          </div>
        );
        break;
      case "boolean toggle":
        setPage(<ToggleButton checked={currentProperties[property.name]} id={property.name} onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: e.value }, currentPage)} />);
        break;
      case "text select":
        setPage(
          <Dropdown
          className="input"
            value={currentProperties[property.name]}
            options={property.options.map((option, idx) => ({ label: option, value: option }))}
            onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: e.value }, currentPage)}
          />
        );
        break;
      case "number inputBox":
        setPage(<InputNumber className="input" value={currentProperties[property.name]} onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: e.value }, currentPage)} />);
        break;
      case "text textArea":
        setPage(<InputTextarea className="input" value={currentProperties[property.name]} onChange={(e) => onPagePropertyChange({ ...currentProperties, [property.name]: e.target.value }, currentPage)} />);
        break;
      case "text image":
        setPage(
          <FileUpload
            name="demo[]"
            url="./upload"
            customUpload
            uploadHandler={(e) => {
              e.id = property.name;
              fileUpload(e);
            }}
            mode="basic"
          />
        );
        break;
    }
  }, [currentProperties]);

  const onChange = (event) => {
    let parsedValue = event.target.value;

    switch (event.target.type) {
      case "number":
        parsedValue = Number(parsedValue);
        break;
      default:
        break;
    }

    onPagePropertyChange({ ...currentProperties, [event.target.id]: parsedValue }, currentPage);
  };

  const fileUpload = (e) => {
    const file = e.files[0];
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
      onPagePropertyChange({ ...currentProperties, [e.id]: reader.result, fill: null }, currentPage);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div>
      <label>{property.name}</label>
      {element}
    </div>
  );
});

export default PropertyPageBox;
