import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
import { Toolbar } from "primereact/toolbar";
import "./dashboard.scss";
import { Button, Menu } from "primereact";
import AddProjectForm from "../../components/add-project-form/add-project-form";
import { Skeleton } from "primereact/skeleton";
import { InputText } from "primereact/inputtext";
import Swal from "sweetalert2";
import { useRef } from "react";


const ProjectMenu = ({ project }) => {
  const menu = useRef(null);
  const items = [
    {
      label: "Options",
      items: [
        {
          label: "Use as template",
          icon: "pi pi-refresh",
        }
      ],
    },
  ];

  const buttonClick = (event) => {
    event.stopPropagation();
    menu.current.toggle(event);
  };

  return (
    <div>
      <Menu model={items} popup ref={menu} id="popup_menu" />
      <Button icon="pi pi-ellipsis-v" className="p-button-rounded p-button-text" style={{ color: "white" }} onClick={buttonClick} aria-controls="popup_menu" aria-haspopup />
    </div>
  );
};

const Dashboard = observer(({ dataService, authService, notificationService, appStructureTreeViewService }) => {
  const [projects, setProjects] = useState([]);
  const [templates, setTemplates] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [showTemplatesSkeleton, setShowTemplatesSkeleton] = useState(true);

  const [showAddForm, setShowAddForm] = useState(false);
  const navigate = useNavigate();
  const toggleAddForm = () => setShowAddForm(!showAddForm);

  const rightContents = (
    <div className="p-d-flex p-flex-wrap toolbar-right-section" style={{ gap: "8px" }}>
      <div className="col-12">
        <div className="p-inputgroup">
          <InputText placeholder="Keyword" onChange={(e) => setSearchKeyword(e.target.value)} />
          <Button icon="pi pi-search" className="p-button-warning" />
        </div>
      </div>
      <Button icon="pi pi-plus" className="p-button-success" label="Add a project" onClick={() => setShowAddForm(true)} />
      <Button icon="pi pi-sign-out" className="p-button-error" onClick={() => authService.signOut()} />
    </div>
  );

  const retrieveProjects = async () => {
    const projectResponse = await dataService.dataRequest(
      {
        tableId: "cl9v1capu000l09l87ldih91x",
        authToken: authService.user.authToken,
        url: "data/item/retrieveItemsFromFilter",
        itemFilter: {
          orPredicates: [
            {
              andPredicates: [
                {
                  attributeId: "kz_deleted",
                  operator: "NEQ",
                  values: ["true"],
                },
              ],
            },
          ],
        },
        sortAscending: false,
      },
      true
    );

    if (projectResponse.status === 1) {
      setProjects(projectResponse.data);
      setShowSkeleton(false);
    }
  };

  const retrieveTemplates = async () => {
    const projectResponse = await dataService.dataRequest(
      {
        tableId: "cl9v1capu000l09l87ldih91x",
        authToken: authService.user.authToken,
        url: "data/item/retrieveItemsFromFilter",
        itemFilter: {
          orPredicates: [
            {
              andPredicates: [
                {
                  attributeId: "kz_deleted",
                  operator: "NEQ",
                  values: ["true"],
                },
                {
                  attributeId: "claabvrg8000j08kz89qi7m1b",
                  operator: "EQ",
                  values: ["true"],
                },
                {
                  attributeId: "clab0zrfu000j08jr53jnce3t",
                  operator: "EQ",
                  values: ["true"],
                },
              ],
            },
          ],
        },
        sortAscending: false,
      },
      true
    );

    if (projectResponse.status === 1) {
      setTemplates(projectResponse.data);

      setShowTemplatesSkeleton(false);
    }
  };

  useEffect(() => {
    if (authService && dataService && authService.user) {
      retrieveProjects();
      retrieveTemplates();
    }
  }, [authService, dataService, authService.user]);

  const navigateToProject = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const getContrastYIQ = (hexcolor) => {
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? "black" : "white";
  };

  const setProject = async (project) => {
    await appStructureTreeViewService.setCurrentProject(project);
    navigateToProject(project._id);
  };

  const getProjectBackgroundStyling = (project) => {
    if (project.cla7f0pvr000j08mf6bl52jg5) {
      return { backgroundImage: `url(${project.cla7f0pvr000j08mf6bl52jg5})`, backgroundSize: "cover", backgroundColor: "#" + project.cl9wfhqx1000j09mideyu5e28 };
    } else {
      return project.cl9wfhqx1000j09mideyu5e28 ? { backgroundColor: "#" + project.cl9wfhqx1000j09mideyu5e28, color: getContrastYIQ(`${project.cl9wfhqx1000j09mideyu5e28}`) } : {};
    }
  };

  const removeProject = (project) => {
    Swal.fire({
      title: "",
      text: "Do you want to delete this project?",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: `No`,
      confirmButtonColor: "#ff3d32",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await appStructureTreeViewService.removeProject(project);

        retrieveProjects();
      }
    });
  };

  const onDelButtonPress = (e, project) => {
    e.stopPropagation();

    removeProject(project);
  };

  return (
    <div className="Dashboard">
      <Toolbar style={{ gap: "8px" }} className="toolBar" left={<img src="/Studio K Logo.svg" alt="logo" className="logo" />} right={rightContents} />

      {showAddForm ? <AddProjectForm authService={authService} notificationService={notificationService} dataService={dataService} toggleForm={toggleAddForm} retrieveProjects={retrieveProjects} /> : ""}

      <div className="projectSection">
        <h1>Projects</h1>

        <div className="projectsContainer">
          {showSkeleton ? (
            <Skeleton width="100%" height="300px" />
          ) : (
            projects
              .filter((project) => project.cl9v1e6yx000n09lccaf29eou.includes(searchKeyword))
              .map((project) => (
                <div key={project._id} className="projectBox" onClick={() => setProject(project)} style={getProjectBackgroundStyling(project)}>
                  <div className="footer">
                    <div className="project-details">
                      <span>{project.cl9v1e6yx000n09lccaf29eou}</span>
                      <span className="last-edited subinfo">Last Edited: {project.kz_updated.localStringDateOnly}</span>
                    </div>
                    <div className="action-buttons">
                      <Button className="p-button-rounded p-button-text" icon="pi pi-trash" style={{ border: "none", color: "white" }} onClick={(e) => onDelButtonPress(e, project)}></Button>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>

        <h1>Templates</h1>

        <div className="templatesContainer">
          {showTemplatesSkeleton ? (
            <Skeleton width="100%" height="300px" />
          ) : (
            templates
              .filter((project) => project.cl9v1e6yx000n09lccaf29eou.includes(searchKeyword))
              .map((project) => (
                <div key={project._id} className="projectBox" style={getProjectBackgroundStyling(project)}>
                  <div className="footer">
                    <div className="project-details">
                      <span>{project.cl9v1e6yx000n09lccaf29eou}</span>
                      <span className="last-edited subinfo">Last Edited: {project.kz_updated.localStringDateOnly}</span>
                    </div>

                    <div className="action-buttons">
                      <ProjectMenu />
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
});

export default Dashboard;
