import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import "./left-menu-list.scss";

//contexts import
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";

//material component import
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { Tree } from "primereact/tree";
import { Button } from "primereact/button";

import cuid from "cuid";
import TreeNode from "../tree-node/tree-node";

export const LeftMenuList = observer(() => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);

  const [selectedNode, setSelectedNode] = useState(null);

  const setCurrentPage = (page) => {
    appStructureTreeViewService.changePage(page);
  };


  const collapseAll = () => {
    setSelectedNode({});
}

  const onNodeSelect = (event) => {
    if (event.node.nodeType === "page") {
      setCurrentPage(event.node.page);
    } else if (event.node.nodeType === "group") {
      appStructureTreeViewService.changeGroup(event.node.group);
    } else if (event.node.nodeType === "element") {
      appStructureTreeViewService.addE
      appStructureTreeViewService.selectElement(event.node.element);
    }
  };

  const onNodeUnselect = (event) => {
    appStructureTreeViewService.currentPage = null;
  };

  const onNodeExpand = (event) => {
    if (event.node.nodeType === "page") {
      setCurrentPage(event.node.page);
    } else if (event.node.nodeType === "group") {
      appStructureTreeViewService.changeGroup(event.node.group);
    } else if (event.node.nodeType === "element") {
      appStructureTreeViewService.selectElement(event.node.element);
    }
  };

  return (
    <div className="leftMenuList">
      <label>Pages</label>

      <Tree
        value={appStructureTreeViewService.getCurrentDataForTreeView()}
        onSelectionChange={(e) => setSelectedNode(e.value)}
        onSelect={onNodeSelect}
        onExpand={onNodeExpand}
        selectionMode="single"
        selectionKeys={selectedNode}
        nodeTemplate={TreeNode}
        dragdropScope={"demo"}
        onDragDrop={(event) => appStructureTreeViewService.handleDragDrop(event.value)}
      />
    </div>
  );
});
