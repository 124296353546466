import { observer } from 'mobx-react';
import { Toast } from 'primereact';
import React, { useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './home.scss';

const Home = observer(({ authService, notificationService }) => {
    const toast = useRef(null);
    const navigate = useNavigate();
    const currentUser = authService.user;


    useEffect(() => {

        if (authService) {
            if (!authService?.user?.authToken) {
                navigate('/landing');
            }
            else{
                navigate('/dashboard')
            }
        }
    }, [authService, currentUser]);

    useEffect(() => {
        if (notificationService.notifications?.length) {
            notificationService.notifications.forEach(notification => {
                toast.current.show(notification);
            })
        }
    }, [notificationService.notifications])

    

    return (
        <div className="Home">
            <Outlet />
            <Toast ref={toast}></Toast>
        </div>
    );
})

export default Home;
