import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Card, ColorPicker, InputText } from "primereact";
import "./edit-project-form.scss";
import { isNullOrUndefined } from "../../utils/isNullOrUndefined";

const EditProjectForm = observer(({ appStructureTreeViewService }) => {

  const [projectDetails, setProjectDetails] = useState({});

  const onDetailsChange = (e) => {
    setProjectDetails({
      ...projectDetails,
      [e.target.id]: e.target.value,
    });
  };

  const setProjectProperties = (property, value)=>{
    setProjectDetails({
        ...projectDetails,
        [property]: value,
      });
  }

  useEffect(() => {
    const isAnyPropertyNullorUndefined = Object.keys(projectDetails).some(key => isNullOrUndefined(projectDetails[key]));
    console.log(isAnyPropertyNullorUndefined);
    if(!isAnyPropertyNullorUndefined){
      appStructureTreeViewService.setter('currentProject', {
        ...appStructureTreeViewService.getter('currentProject'),
        ...projectDetails
      })
    }
  }, [projectDetails])


  useEffect(() => {
    setProjectDetails({
      ...appStructureTreeViewService.getter('currentProject')
    })
  }, [])

  return (
    <div className="EditProjectForm">
      <h5 className="headerSmall" style={{ margin: 0 }}>
        Title
      </h5>
      <InputText onChange={(e) => onDetailsChange(e)} placeholder="Project title" id="cl9v1e6yx000n09lccaf29eou" value={projectDetails.cl9v1e6yx000n09lccaf29eou} />
      <span>Project Colour</span>
      <ColorPicker value={ projectDetails.cl9wfhqx1000j09mideyu5e28} onChange={(e) => setProjectProperties( 'cl9wfhqx1000j09mideyu5e28', e.value)} />

      <div className="checkbox-group">
        <Checkbox id="isPublic" onChange={(e) => setProjectProperties('claabvrg8000j08kz89qi7m1b', e.checked )} checked={projectDetails.claabvrg8000j08kz89qi7m1b}></Checkbox>
        <label htmlFor="isPublic" className="p-checkbox-label">
          Is Public?
        </label>
      </div>

      <div className="checkbox-group">
        <Checkbox id="isTemplate" onChange={(e) => setProjectProperties('clab0zrfu000j08jr53jnce3t', e.checked )} checked={projectDetails.clab0zrfu000j08jr53jnce3t}></Checkbox>
        <label htmlFor="isTemplate" className="p-checkbox-label">
          Is Template?
        </label>
      </div>
    </div>
  );
});

export default EditProjectForm;
