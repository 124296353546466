import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Stage, Text, Layer, Transformer, Rect } from "react-konva";
import "./canvas-stage.scss";
//contexts import
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";
import CanvasElement from "../canvas-element/canvas-element";
import CanvasPage from "../canvas-page/canvas-page";
import { toJS } from "mobx";
import { Group } from "react-konva";
import DomPage from "../dom-page/dom-page";
import { BsFillMouseFill } from 'react-icons/bs';
import imageCompression from "browser-image-compression";

const CanvasStage = observer(({ originalOrigin, setOriginalOrigin, savingProject, setSavingProject, notificationService }) => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);
  const currentPage = appStructureTreeViewService.getCurrentPage();
  const [canvasRef, setCanvasRef] = useState(null);
  const [layerRef, setLayerRef] = useState(null);
  const [pageGroupRef, setPageGroupRef] = useState(null);
  const [stageProps, setStageProps] = useState({
    x: 0,
    y: 0,
    scale: 1,
  });



  useEffect(() => {

    return () => {
      appStructureTreeViewService.setter('selectedElements', []);
    }
  }, [])


  useEffect(() => {
    if (savingProject && canvasRef) {

      (async () => {

        const file = dataURItoBlob(canvasRef.toDataURL());

        const options = {
          maxSizeMB: 0.4,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };

        const compressedFile = await imageCompression(file, options);

        // Encode the file using the FileReader API
        const reader = new FileReader();
        reader.onloadend = () => {
          appStructureTreeViewService.setter('projectThumbnail', reader.result);

          (async () => {
            await appStructureTreeViewService.syncProject();

          })()

        };
        reader.readAsDataURL(compressedFile);

      })()

      setSavingProject(false);



    }

  }, [savingProject, canvasRef])


  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  // section for multiselect code
  const [nodesArray, setNodes] = React.useState([]);
  const [trRef, setTrRef] = useState(null);
  const Konva = window.Konva;

  const selectionRectRef = React.useRef();
  const selection = React.useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  });

  const GUIDELINE_OFFSET = 5;

  // were can we snap our objects?
  function getLineGuideStops(skipShape) {
    // we can snap to canvasRef borders and the center of the canvasRef
    var vertical = [0, canvasRef.width() / 2, canvasRef.width()];
    var horizontal = [0, canvasRef.height() / 2, canvasRef.height()];

    // and we snap over edges and center of each object on the canvas
    canvasRef.find('.object').forEach((guideItem) => {
      if (guideItem === skipShape) {
        return;
      }
      var box = guideItem.getClientRect();
      // and we can snap to all edges of shapes
      vertical.push([box.x, box.x + box.width, box.x + box.width / 2]);
      horizontal.push([box.y, box.y + box.height, box.y + box.height / 2]);
    });
    return {
      vertical: vertical.flat(),
      horizontal: horizontal.flat(),
    };
  }

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  function getObjectSnappingEdges(node) {
    var box = node.getClientRect();
    var absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end',
        },
      ],
    };
  }

  // find all snapping possibilities
  function getGuides(lineGuideStops, itemBounds) {
    var resultV = [];
    var resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        var diff = Math.abs(lineGuide - itemBound.guide);


        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        var diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });


    var guides = [];

    // find closest snap
    var minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    var minH = resultH.sort((a, b) => a.diff - b.diff)[0];
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      });
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      });
    }
    return guides;
  }

  function drawGuides(guides) {
    guides.forEach((lg) => {
      if (lg.orientation === 'H') {
        var line = new Konva.Line({
          points: [-6000, 0, 6000, 0],
          stroke: 'rgb(1, 161, 255)',
          strokeWidth: 2,
          name: 'guid-line',
          dash: [4, 6],
        });
        layerRef.add(line);
        line.absolutePosition({
          x: 0,
          y: lg.lineGuide,
        });
      } else if (lg.orientation === 'V') {
        var line = new Konva.Line({
          points: [0, -6000, 0, 6000],
          stroke: 'rgb(1, 161, 255)',
          strokeWidth: 2,
          name: 'guid-line',
          dash: [4, 6],
        });
        layerRef.add(line);
        line.absolutePosition({
          x: lg.lineGuide,
          y: 0,
        });
      }
    });
  }




  useEffect(() => {
    if (layerRef) {
      layerRef.on('dragmove', function (e) {

        // clear all previous lines on the screen
        layerRef.find('.guid-line').forEach((l) => l.destroy());

        // find possible snapping lines
        var lineGuideStops = getLineGuideStops(e.target);



        // find snapping points of current object
        var itemBounds = getObjectSnappingEdges(e.target);


        // now find where can we snap current object
        var guides = getGuides(lineGuideStops, itemBounds);


        // do nothing of no snapping
        if (!guides.length) {
          return;
        }

        drawGuides(guides);

        var absPos = e.target.absolutePosition();
        // now force object position
        guides.forEach((lg) => {
          switch (lg.snap) {
            case 'start': {
              switch (lg.orientation) {
                case 'V': {
                  absPos.x = lg.lineGuide + lg.offset;
                  break;
                }
                case 'H': {
                  absPos.y = lg.lineGuide + lg.offset;
                  break;
                }
              }
              break;
            }
            case 'center': {
              switch (lg.orientation) {
                case 'V': {
                  absPos.x = lg.lineGuide + lg.offset;
                  break;
                }
                case 'H': {
                  absPos.y = lg.lineGuide + lg.offset;
                  break;
                }
              }
              break;
            }
            case 'end': {
              switch (lg.orientation) {
                case 'V': {
                  absPos.x = lg.lineGuide + lg.offset;
                  break;
                }
                case 'H': {
                  absPos.y = lg.lineGuide + lg.offset;
                  break;
                }
              }
              break;
            }
          }
        });
        e.target.absolutePosition(absPos);
      });

      layerRef.on('dragend', function (e) {
        // clear all previous lines on the screen
        layerRef.find('.guid-line').forEach((l) => l.destroy());
      });
    }
  }, [layerRef])

  const updateLayerRef = (element) => {
    if (element) {
      setLayerRef(element);
      redrawTransformer(element, trRef);
    }
  };

  const updateTrRef = (element) => {
    if (element) {
      setTrRef(element);
    }
  };

  const updateCanvasRef = (element) => {
    if (element && element.x() && element.y() && originalOrigin.untouched) {
      setOriginalOrigin({
        x: element.x(),
        y: element.y(),
        untouched: false,
      });
    }

    setCanvasRef(element);
  };

  const getAllNodes = (layer, accumulator) => {
    for (let i = 0; i < layer.children.length; i++) {
      accumulator.push(layer.children[i]);

      if (layer.children[i].hasOwnProperty("children")) {
        getAllNodes(layer.children[i], accumulator);
      }
    }
  };

  const redrawTransformer = (lrRef = layerRef, tRef = trRef) => {
    if (appStructureTreeViewService.selectedElements.length && lrRef && tRef) {
      tRef.nodes([]);
      const allChildren = [];

      getAllNodes(lrRef, allChildren);

      allChildren
        .filter((child) => appStructureTreeViewService.selectedElements.map((element) => element.id).includes(child.attrs.id))
        .forEach((elementNode) => {
          // we need to attach transformer manually

          if (tRef.nodes() && tRef.nodes().length) {
            tRef.nodes(tRef.nodes().concat([elementNode]));
          } else {
            tRef.nodes([elementNode]);
          }
        });

      lrRef.draw();
    } else {
      try {
        tRef.nodes([]);
        lrRef.draw();
      } catch (e) {
        return;
      }
    }
  };

  React.useEffect(() => {
    setTimeout(redrawTransformer, 10);
  }, [appStructureTreeViewService.selectedElements, appStructureTreeViewService.elements, appStructureTreeViewService.pages, appStructureTreeViewService.groups]);


  const canvasScroll = (e) => {
    if (e.evt.ctrlKey) {
      e.evt.preventDefault();

      const canvasRef = e.target.getStage();
      var scaleBy = 1.02;

      var oldScale = canvasRef.scaleX();

      const mousePointTo = {
        x: canvasRef.getPointerPosition().x / oldScale - canvasRef.x() / oldScale,
        y: canvasRef.getPointerPosition().y / oldScale - canvasRef.y() / oldScale,
      };

      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? -1 : 1;

      // // when we zoom on trackpad, e.evt.ctrlKey is true
      // // in that case lets revert direction
      // if (e.evt.ctrlKey) {
      //   direction = -direction;
      // }

      var newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      // canvasRef.scale({ x: newScale, y: newScale });

      // var newPos = {
      //   x: pointer.x - mousePointTo.x * newScale,
      //   y: pointer.y - mousePointTo.y * newScale,
      // };
      setStageProps({
        scale: newScale,
        x: -(mousePointTo.x - canvasRef.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - canvasRef.getPointerPosition().y / newScale) * newScale,
      });

      const canvas = document.querySelector("canvas");

      if (canvas) {
        const ctx = canvas.getContext("2d");
      }
    }
  };

  const onDragEnd = function (e) {
    if (e.target === e.target.getStage()) {
      setStageProps({
        ...stageProps,
        x: e.target.x(),
        y: e.target.y(),
      });
    }
  };

  const checkDeselect = (e) => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      appStructureTreeViewService.deselectAllElements();
      trRef.nodes([]);
      setNodes([]);
      layerRef.draw();
    }
  };

  // const updateSelectionRect = () => {
  //   const node = selectionRectRef.current;
  //   node.setAttrs({
  //     visible: selection.current.visible,
  //     x: Math.min(selection.current.x1, selection.current.x2),
  //     y: Math.min(selection.current.y1, selection.current.y2),
  //     width: Math.abs(selection.current.x1 - selection.current.x2),
  //     height: Math.abs(selection.current.y1 - selection.current.y2),
  //     fill: "rgba(0, 161, 255, 0.3)",
  //   });
  //   node.getLayer().batchDraw();
  // };

  // const oldPos = React.useRef(null);

  // const onMouseDown = (e) => {
  //   if (e.target !== e.target.getStage()) {
  //     return;
  //   }
  //   const stage = e.target.getStage();

  //   const pos = e.target.getStage().getPointerPosition();
  //   //console.log(pos);
  //   selection.current.visible = true;
  //   //console.log(originalOrigin.x, originalOrigin.y);
  //   selection.current.x1 = pos.x;
  //   selection.current.y1 = pos.y;
  //   selection.current.x2 = pos.x;
  //   selection.current.y2 = pos.y;

  //   updateSelectionRect();
  // };

  // const onMouseMove = (e) => {
  //   if (!selection.current.visible) {
  //     return;
  //   }
  //   const stage = canvasRef;
  //   const pos = e.target.getStage().getPointerPosition();
  //   selection.current.x2 = pos.x;
  //   selection.current.y2 = pos.y;

  //   updateSelectionRect();
  // };

  // const onMouseUp = () => {
  //   oldPos.current = null;
  //   if (!selection.current.visible) {
  //     return;
  //   }
  //   const selBox = selectionRectRef.current.getClientRect();

  //   const elements = [];
  //   layerRef
  //     .find((node) => node.attrs.id)
  //     .forEach((elementNode) => {
  //       const elBox = elementNode.getClientRect();
  //       if (Konva.Util.haveIntersection(selBox, elBox)) {
  //         elements.push(elementNode);
  //       }
  //     });

  //   appStructureTreeViewService.addElements(elements.map((node) => node.attrs.id));
  //   selection.current.visible = false;
  //   // disable click event
  //   Konva.listenClickTap = false;
  //   updateSelectionRect();
  // };

  return (
    <div>
      <div className="canvasContainer card">
        <div className="p-d-flex p-ai-center p-mb-1 scroll-tip" style={{ gap: '0.5rem' }}>
          <i className="pi pi-pencil"></i>{appStructureTreeViewService.currentProject?.cl9v1e6yx000n09lccaf29eou}
        </div>

        <Stage
          ref={(element) => updateCanvasRef(element)}
          width={1500}
          height={1500}
          x={stageProps.x}
          y={stageProps.y}
          scaleX={stageProps.scale}
          scaleY={stageProps.scale}
          onWheel={canvasScroll}
          onTouchStart={checkDeselect}
          listening={true}
          // onMouseDown={onMouseDown}
          // onMouseUp={onMouseUp}
          // onMouseMove={onMouseMove}
          onClick={checkDeselect}
          onDragEnd={onDragEnd}
          draggable
          _useStrictMode
        >
          <Layer ref={(element) => updateLayerRef(element)} blue="50">
            {appStructureTreeViewService && currentPage && appStructureTreeViewService.pages && !appStructureTreeViewService.isNullOrUndefined(appStructureTreeViewService.currentPageId) ? (
              currentPage && currentPage.properties && currentPage.properties.overflow === 'hidden' || appStructureTreeViewService.mode === 'designer' ? <Group
                clipFunc={(ctx) => {

                  let { x, y, width, height, cornerRadius } = currentPage.properties;
                  if (!cornerRadius) {
                    cornerRadius = 0;
                  }

                  x = 0;
                  y = 0;

                  if (appStructureTreeViewService.positionalProperties[currentPage.id]) {
                    width = appStructureTreeViewService.positionalProperties[currentPage.id].width;
                    height = appStructureTreeViewService.positionalProperties[currentPage.id].height;
                  }
                  ctx.beginPath();
                  ctx.moveTo(x + cornerRadius, y);
                  ctx.lineTo(x + width - cornerRadius, y);
                  ctx.quadraticCurveTo(x + width, y, x + width, y + cornerRadius);
                  ctx.lineTo(x + width, y + height - cornerRadius);
                  ctx.quadraticCurveTo(x + width, y + height, x + width - cornerRadius, y + height);
                  ctx.lineTo(x + cornerRadius, y + height);
                  ctx.quadraticCurveTo(x, y + height, x, y + height - cornerRadius);
                  ctx.lineTo(x, y + cornerRadius);
                  ctx.quadraticCurveTo(x, y, x + cornerRadius, y);
                  ctx.closePath();

                }}
              >
                <CanvasPage page={appStructureTreeViewService.getCurrentPage()} appStructureTreeViewService={appStructureTreeViewService} />
              </Group> :
                <Group>
                  <CanvasPage page={appStructureTreeViewService.getCurrentPage()} appStructureTreeViewService={appStructureTreeViewService} />
                </Group>

            ) : (
              ""
            )}

            <Transformer
              // ref={trRef[getKey]}
              ref={(element) => updateTrRef(element)}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (newBox.width < 5 || newBox.height < 5) {
                  return oldBox;
                }

                console.log(newBox);

                return newBox;
              }}
            />
            {/* {canvasRef ? <Rect fill="rgba(255,255,255,0.5)" x={stageProps.x} y={stageProps.y} width={10} height={10}></Rect> : ""} */}
            {/* <Rect fill="rgba(0,235,255,0.5)" x={originalOrigin.x} y={originalOrigin.y} width={1200} height={1200} /> */}

            {/* { originalOrigin ? <Rect fill="rgba(0,255,255,0.5)" x={originalOrigin.x} y={originalOrigin.y} width={10} height={10}></Rect>: ''} */}
            {/* 
            <Rect fillPatternImage={new Image("./spaceship.png")} ref={selectionRectRef} /> */}
          </Layer>
        </Stage>

        <div className="p-d-flex p-ai-center p-mt-1 scroll-tip" style={{ gap: '0.5rem' }}>
          Ctrl +  <BsFillMouseFill />  to zoom in or out
        </div>
      </div>
    </div >
  );
});

export default CanvasStage;
