import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App.js";
import Dashboard from "../pages/dashboard/dashboard.jsx";
import Home from "../pages/home/home.jsx";
import { Landing } from "../pages/landing/landing.jsx";
import Signin from "../pages/signin/signin.jsx";
import Signup from "../pages/signup/signup.jsx";
import { AppStructureTreeViewService } from "../services/app-structure-service";
import { AuthService } from "../services/auth-service";
import { DataService } from "../services/data-service.js";
import { NotificationService } from "../services/notification-service";
import { SocketService } from "../services/socket-service.js";

const notificationService = new NotificationService();
const socketService = new SocketService();
const authService = new AuthService(notificationService, socketService);
const dataService = new DataService(notificationService, authService, socketService);
const appStructureTreeViewService = new AppStructureTreeViewService(dataService, authService, notificationService);

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home authService={authService} notificationService={notificationService} dataService={dataService} />,
    children: [
      {
        path: "landing",
        element: <Landing />,
      },
      {
        path: "dashboard",
        element: <Dashboard authService={authService} notificationService={notificationService} dataService={dataService} appStructureTreeViewService={appStructureTreeViewService} />,
      },
      {
        path: "projects/:projectId",
        element: <App appStructureTreeViewService={appStructureTreeViewService} dataService={dataService} authService={authService} notificationService={notificationService} />,
      },
      {
        path: "signup",
        element: <Signup authService={authService} notificationService={notificationService} dataService={dataService} />,
      },
      {
        path: "signin",
        element: <Signin authService={authService} notificationService={notificationService} dataService={dataService} />,
      },
    ],
  },
]);
