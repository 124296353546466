import { Button, Toolbar } from "primereact";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./landing.scss";

export const Landing = () => {
  const navigate = useNavigate();

  const [text, setText] = useState("Templates");
  const [pointerPosition, setPointerPosition] = useState({
    x: 0,
    y: 0,
  });

  const onMouseMove = (e) => {
    setPointerPosition({
      x: e.clientX,
      y: e.clientY,
    });
  };


  const changeText = () => {
    switch (text.toLowerCase()) {
      case "templates":
        setText("Projects");
        break;
      case "projects":
        setText("Icons");
        break;
      case "icons":
        setText("Templates");
        break;
    }
  };

  const getColor = () => {
    switch (text.toLowerCase()) {
      case "templates":
        return "#FFD600";
      case "projects":
        return "#9747FF";
      case "icons":
        return "#4FE8D0";
    }
  };

  const getBackgroundPositionStyling = () => {
    if (![null, undefined].includes(pointerPosition.x) && ![null, undefined].includes(pointerPosition.y)) {
      let pos = {
        top: 0,
        left: 0,
      };

      if (pointerPosition.x > window.innerWidth / 2) {
        pos.left = `${-(pointerPosition.x - window.innerWidth / 2) * 0.05}px`;
      }

      if (pointerPosition.y > window.innerHeight / 2) {
        pos.top = `${-(pointerPosition.y - window.innerHeight / 2) * 0.05}px`;
      }

      if (pointerPosition.x <= window.innerWidth / 2) {
        pos.left = `${-(pointerPosition.x - window.innerWidth / 2) * 0.05}px`;
      }

      if (pointerPosition.y <= window.innerHeight / 2) {
        pos.top = `${-(pointerPosition.y - window.innerHeight / 2) * 0.05}px`;
      }

      return pos;
    } else {
      return { top: 0, left: 0 };
    }
  };


  useEffect(() => {
    setTimeout(() => changeText(), 2000);
  }, [text]);


  const sendNotification = async () => {

    const registration = await navigator.serviceWorker.getRegistration();

    if(Notification.permission === 'granted') {
      showNotification('test', registration);
    }
    else {
      if(Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();
    
        if(permission === 'granted') {
          showNotification('test', registration);
        }
      }
    }
    };
    
    const showNotification = (body, registration) => {
    const title = 'What PWA Can Do Today';
    
    const payload = {
      body
    };
    
    if('showNotification' in registration) {
      registration.showNotification(title, payload);
    }
    else {
      new Notification(title, payload);
    }
  };

  const getSpan = () => {
    return (
      <span key={text} className="pseudo-after-animated" style={{ color: getColor(), fontWeight: "900" }}>
        {text}
      </span>
    );
  };

  return (
    <div className="Landing">
      <Toolbar left={<img src="./Studio K Logo.svg" className="logo" />} right={<Button label="Demo" icon="pi pi-arrow-right" onClick={() => navigate("/signin")} style={{ backgroundColor: "var(--purple-500)", color: "white" }}></Button>} />
      <div className="mainSection">
        <div className="leftSection">
          <h1>Your personal design space</h1>
          <span>Everything in one place. {getSpan()}</span>
          <Button label="Coming soon" iconPos="right" onClick={sendNotification}></Button>
        </div>

        <div className="rightSection">
          <video autoPlay loop muted>
            <source src="/videos/studiok_hero_video.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      {/* <div className="landingBackground" style={getBackgroundPositionStyling()}></div> */}
    </div>
  );
};
