import { observable, makeAutoObservable, toJS } from "mobx";
import _ from "lodash";
import WebFont from "webfontloader";
import cuid from "cuid";
import axios from "axios";

export class NotificationService {
  notifications = [];

  constructor() {
    makeAutoObservable(this);
  }

  setter = (propertyName, value) => {
    this[propertyName] = value;
  };

  getter = (propertyName) => {
    return this[propertyName];
  };


  addNotifications = (notifications) =>{
    this.notifications = notifications;
  }

}
