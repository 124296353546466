import cuid from "cuid";
import { Button } from "primereact/button";
import React, { useContext, useRef } from "react";
import "./tree-node.scss";
import { AppStructureTreeViewContext } from "../../contexts/app-structure-tree-view-context";

const TreeNode = (node, options) => {
  const appStructureTreeViewService = useContext(AppStructureTreeViewContext);





  const checkForAddEligibility = () => {

    if (node.nodeType === "page" && appStructureTreeViewService.getCurrentPage() && node.pageId === appStructureTreeViewService.getCurrentPage().id) {
      return true;
    }

    return false;
  };

  const showAddButton = checkForAddEligibility();

  const addButtonClick = () => {
    appStructureTreeViewService.addGroupToCurrentPage(appStructureTreeViewService.getNewGroupName());
  };

  const deleteButtonClick = (e) => {
    e.stopPropagation();
    appStructureTreeViewService.removeNode(node);
  };

  const toggleNode = (e) => {
    e.stopPropagation();
    appStructureTreeViewService.toggleNode(node);
  };

  return (

    <span className={`treeNode ${node.nodeType.toLowerCase() === "element" ? "elementNode" : ""}`}>
      {node.label}
      <div className="buttonGroup">
        {showAddButton ? <Button icon="pi pi-plus" className="p-button-outlined" onClick={addButtonClick}></Button> : ""}
        {node.nodeType !== "page" ? <Button icon={`pi ${node.isHidden ? "pi-eye-slash" : "pi-eye"}`} className="p-button-outlined" onClick={toggleNode}></Button> : ""}
        {node.nodeType === "page" ? <Button icon={`pi pi-ellipsis-h`} className="p-button-outlined"></Button> : ''}
        <Button icon="pi pi-trash" className="p-button-outlined" onClick={deleteButtonClick}></Button>
      </div>
    </span>
  );
};

export default TreeNode;
